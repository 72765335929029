import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Toolbar,
  } from "@mui/material";
  import React, { useCallback, useState, useMemo } from "react";
  import { emphasize } from '@mui/material/styles';
  import { styled as muiStyled } from "@mui/material/styles";
  import {
    Add,
    AutoAwesome,
    BarChart,
    Chat,
    CheckBoxOutlined,
    Close,
    Comment,
    CommentOutlined,
    ContentCopyOutlined,
    CopyAllOutlined,
    DescriptionOutlined,
    DoneAllOutlined,
    DoneAllRounded,
    DragHandle,
    DragHandleOutlined,
    ExpandLess,
    ExpandMore,
    FolderOutlined,
    GroupOutlined,
    OpenInNew,
    Search,
    VideoFileOutlined,
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useUser, UserButton } from "@clerk/clerk-react";
  import SiftsyAuthProvider from "./SiftsyAuthProvider";
  import { useNavigate } from "react-router-dom";

  import HelperFunctions from "./HelperFunctions";
import GroupView from "./GroupView";
import { formatData, formatNumber, formatPlatform, UTCtoLocal } from './Helpers'; 
import Footer from "./Footer";
import AddNewModal from "./AddNewModal";
import axios from 'axios';


  const ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const darkMode = false;
  const theme = createTheme(siftsyTheme);

  function CreateTeamModal({ open, onClose, onSuccess }) {
    const [teamName, setTeamName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [newTeamId, setNewTeamId] = useState(null);
    const Functions = HelperFunctions();
    const { user } = useUser();
    const navigate = useNavigate();

    const handleCreateTeam = async () => {
      const trimmedName = teamName.trim();
      if (!trimmedName) {
        setError('Team name is required');
        return;
      }

      if (trimmedName.length > 100) {
        setError('Team name must be 100 characters or less');
        return;
      }

      setLoading(true);
      setError('');

      try {
        // Create the team
        const newTeam = await Functions.createTeam(trimmedName);
        
        if (newTeam) {
          // Get or create Siftsy user
          const siftsyUser = await Functions.fetchUser(user.primaryEmailAddress.emailAddress);
          
          if (siftsyUser) {
            // Link user to team
            await Functions.linkUserTeam(siftsyUser.id, newTeam.id);
            setNewTeamId(newTeam.id);
            setSuccess(true);
            onSuccess();
            setTeamName('');
          } else {
            setError('Failed to create team. Please try again.');
          }
        } else {
          setError('Failed to create team. Please try again.');
        }
      } catch (error) {
        console.error('Error creating team:', error);
        setError('Failed to create team. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && !loading) {
        handleCreateTeam();
      }
    };

    const handleClose = () => {
      onClose();
      // Reset states after animation completes
      setTimeout(() => {
        setSuccess(false);
        setNewTeamId(null);
      }, 300);
    };

    const goToNewTeam = () => {
      handleClose();
      navigate(`/team/${newTeamId}`);
    };

    return (
      <Dialog 
        open={open} 
        onClose={loading ? undefined : handleClose}
        maxWidth="sm" 
        fullWidth
      >
        {success ? (
          <>
            <DialogContent>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                py: 3,
                gap: 2
              }}>
                <Box sx={{ 
                  width: 40, 
                  height: 40, 
                  borderRadius: '50%', 
                  bgcolor: 'success.main',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1
                }}>
                  <DoneAllRounded sx={{ color: 'white' }} />
                </Box>
                <Typography variant="h6" align="center">
                  Team Created Successfully!
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  Your new team is ready to go
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2, pt: 0 }}>
              <Button 
                onClick={handleClose}
                sx={{ borderRadius: 2 }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={goToNewTeam}
                sx={{ borderRadius: 2 }}
                endIcon={<OpenInNew />}
              >
                Go to Team
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Create New Team</DialogTitle>
            <DialogContent>
              <Box sx={{ pt: 2 }}>
                <TextField
                  autoFocus
                  label="Team Name"
                  fullWidth
                  value={teamName}
                  onChange={(e) => {
                    setTeamName(e.target.value);
                    setError('');
                  }}
                  onKeyPress={handleKeyPress}
                  error={!!error}
                  helperText={error || `${teamName.length}/100 characters`}
                  disabled={loading}
                  inputProps={{
                    maxLength: 100
                  }}
                  placeholder="Enter your team name"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={handleClose} 
                disabled={loading}
                sx={{ borderRadius: 2 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateTeam}
                variant="contained"
                disabled={loading || !teamName.trim()}
                sx={{ borderRadius: 2 }}
                startIcon={loading ? <CircularProgress size={20} /> : <Add />}
              >
                Create Team
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }

  export default function Dashboard(props) {
    const { isLoaded, isSignedIn, user } = useUser();
    const [teamData, setTeamData] = React.useState(null);
    const [allPostData, setAllPostData] = React.useState(null);
    const [empty, setEmpty] = React.useState(true);
    const [selectedAssets, setSelectedAssets] = React.useState({team: 0, group: 0, post: 0, report: 0});
    const [addNewOpen, setOpenAddNew] = React.useState(false);
    const [typeToAdd, setTypeToAdd] = React.useState("group");
    const [selectedPostData, setSelectedPostData] = React.useState([]);
    const [copied, setCopied] = React.useState(false);  
    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
    const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = React.useState(window.innerHeight);
    const [selectedTeam, setSelectedTeam] = React.useState(null);
    const [teammateData, setTeammateData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [teamUsageData, setTeamUsageData] = React.useState(null);
    const [recentActivity, setRecentActivity] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [filterPlatform, setFilterPlatform] = React.useState('all');
    const navigate = useNavigate();
    const Functions = HelperFunctions();
    const [createTeamOpen, setCreateTeamOpen] = useState(false);

    const fetchUserId = useCallback(async () => {
      if (!user?.primaryEmailAddress?.emailAddress) return null;
      let email = user.primaryEmailAddress.emailAddress;
      console.log("email", email);
      let siftsyUser = await Functions.fetchUser(email);
      console.log("siftsyUser", siftsyUser);
      return siftsyUser?.id;
    }, [user]);

    const fetchData = useCallback(async () => {
      if (!user) return;
      
      setLoading(true);
      try {
        let id = await fetchUserId();
        if (!id) {
          console.log("no id");
          return;
        }

        console.log("id", id);
        let data = await Functions.getUserTeamData(id);
        
        if (data && Array.isArray(data)) {
          setTeamData(data);
          if (data.length > 0) {
            setSelectedTeam(data[0]);
          }
        } else {
          setTeamData([]);
          setSelectedTeam(null);
        }
        
        console.log("teamData", data);
        setEmpty(!data || data.length === 0);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTeamData([]);
        setSelectedTeam(null);
        setEmpty(true);
      } finally {
        setLoading(false);
      }
    }, [user, fetchUserId]);

    React.useEffect(() => {
      let mounted = true;

      if (user && mounted) {
        fetchData();
      }

      return () => {
        mounted = false;
      };
    }, [user, fetchData]);

    const handleSelectedTeamChange = (e, index) => {
      setSelectedTeam(e.target.value);
    };

    React.useEffect(() => {
      const fetchTeammatesForTeams = async () => {
        if (!teamData || !Array.isArray(teamData)) return;
        
        const teammatesMap = {};
        for (const team of teamData) {
          try {
            if (team && team.id) {
              const data = await Functions.getTeammateData(team.id);
              if (data) {
                teammatesMap[team.id] = data;
              }
            }
          } catch (error) {
            console.error(`Error fetching teammates for team:`, error);
          }
        }
        setTeammateData(teammatesMap);
      };

      fetchTeammatesForTeams();
    }, [teamData]);
       


React.useEffect(() => {
  const handleResize = () => {
    setPageWidth(window.innerWidth);
    setViewportWidth(window.innerWidth);
    setViewportHeight(window.innerHeight);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

   
    React.useEffect(() => {
      let mounted = true;

      const fetchTeamUsageData = async () => {
        if (selectedTeam) {
          try {
            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1);
            const data = await Functions.getTeamUsageData(selectedTeam.id, startDate, new Date());
            if (mounted) {
              setTeamUsageData(data);
            }
          } catch (error) {
            console.error("Error fetching team usage data:", error);
          }
        }
      };

      fetchTeamUsageData();
      return () => {
        mounted = false;
      };
    }, [selectedTeam]);

    const getTeamMetrics = () => {
      if (!teamUsageData || !selectedTeam || !selectedTeam.id) return null;
      
      return [
        { label: 'Total Posts', value: teamUsageData.postsCompleted || 0, icon: <BarChart /> },
        { label: 'Active Members', value: teammateData[selectedTeam.id]?.length || 0, icon: <GroupOutlined /> },
        { label: 'Comments Analyzed', value: teamUsageData.commentsAnalyzed || 0, icon: <CommentOutlined /> },
        { label: 'Reports Generated', value: teamUsageData.reportsGenerated || 0, icon: <DescriptionOutlined /> }
      ];
    };

    const getRecentActivity = (team) => {
      if (!team) return [];
      
      const activities = [];
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      
      // Add recent posts if any
      if (team.posts && Array.isArray(team.posts)) {
        const recentPosts = team.posts
          .filter(post => {
            const postDate = new Date(post.data?.postedAt);
            return post.status === 'complete' && postDate >= oneMonthAgo;
          })
          .sort((a, b) => new Date(b.data?.postedAt) - new Date(a.data?.postedAt))
          .slice(0, 2);
          
        recentPosts.forEach(post => {
          const platform = post.data?.platform;
          if (platform) {
            const date = new Date(post.data.postedAt);
            const timeAgo = formatTimeAgo(date);
            if (timeAgo) {
              activities.push(`Analyzed ${formatPlatform(platform)} post ${timeAgo}`);
            }
          }
        });
      }
      
      // Add recent campaigns if any
      if (team.groups && Array.isArray(team.groups)) {
        const recentCampaigns = team.groups
          .filter(group => {
            const groupDate = new Date(group.createdAt);
            return group.type === 'campaign' && groupDate >= oneMonthAgo;
          })
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 1);
          
        recentCampaigns.forEach(campaign => {
          const date = new Date(campaign.createdAt);
          const timeAgo = formatTimeAgo(date);
          if (timeAgo && campaign.name) {
            activities.push(`Created campaign "${campaign.name}" ${timeAgo}`);
          }
        });
      }
      
      return activities;
    };

    // Helper function to format time ago
    const formatTimeAgo = (date) => {
      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInDays > 30) {
        return '';  // Don't show if older than a month
      } else if (diffInDays > 0) {
        return `${diffInDays}d ago`;
      } else if (diffInHours > 0) {
        return `${diffInHours}h ago`;
      } else if (diffInMinutes > 0) {
        return `${diffInMinutes}m ago`;
      } else {
        return 'just now';
      }
    };

    const filteredTeams = React.useMemo(() => {
      if (!teamData || !Array.isArray(teamData)) return [];
      return teamData.filter(team => 
        team && team.id && team.name && team.name.toLowerCase().includes((searchQuery || '').toLowerCase())
      );
    }, [teamData, searchQuery]);

    const handleTeamCreated = useCallback(() => {
      fetchData();
    }, [fetchData]);

    return (
      <ThemeProvider theme={theme}>
        <div style={{
          backgroundColor: "#f7f8fa",
          width: "100%",
          minHeight: viewportHeight,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}>
          <AppBar
            position="static"
            elevation={0}
            sx={{ background: "#fff", borderBottom: "1px solid #ddd" }}
          >
            <Toolbar>
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                maxWidth: 1200,
                width: "100%",
                margin: "0 auto",
                gap: 2
              }}>
                <Box>
                  <ButtonBase>
                    <img
                      src={"/siftsy_logo.png"}
                      alt="logo"
                      style={{ width: 80, height: "auto", padding: "5px" }}
                    />
                  </ButtonBase>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                  <UserButton />
                </Box>
              </Box>
            </Toolbar>
          </AppBar>

          <Box sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "hidden"
          }}>
            <Box sx={{
              maxWidth: 1200,
              width: "100%",
              margin: "0 auto",
              mt: 2,
              px: 4,
            }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  border: "1px solid #eee",
                  background: "#fff",
                }}
              >
                <Box sx={{ 
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flexWrap: "wrap"
                }}>
                  <Box sx={{ 
                    display: "flex", 
                    alignItems: "center",
                    flex: 1,
                    minWidth: 300,
                  }}>
                    <Search sx={{ color: "text.secondary", mr: 1 }} />
                    <TextField
                      size="small"
                      placeholder={`Search ${filteredTeams.length} ${filteredTeams.length === 1 ? 'team' : 'teams'}...`}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loading}
                    />
                  </Box>
                  {/* {!loading && (
                    <Button
                      variant="contained"
                      startIcon={<Add />}
                      onClick={() => setCreateTeamOpen(true)}
                      sx={{ borderRadius: 2, flexShrink: 0 }}
                    >
                      Create Team
                    </Button>
                  )} */}
                </Box>
              </Card>
            </Box>

            <Box sx={{
              maxWidth: 1200,
              width: "100%",
              margin: "0 auto",
              px: 4,
              pt: 2,
              flex: 1,
            }}>
              {loading ? (
                <Box sx={{ width: '100%', mt: 2 }}>
                  <Grid container spacing={3}>
                    {[1, 2, 3].map((index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                          elevation={0}
                          sx={{
                            borderRadius: 3,
                            p: 3,
                            height: 200,
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #eee',
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
                              <Box sx={{ flex: 1 }}>
                                <Skeleton variant="text" width="80%" height={32} />
                                <Skeleton variant="text" width="40%" height={24} />
                              </Box>
                            </Box>
                            <Box sx={{ pl: 7 }}>
                              <Skeleton variant="rounded" width={80} height={24} />
                            </Box>
                          </Box>

                          <Box sx={{ flexGrow: 1 }} />

                          <Skeleton 
                            variant="rounded" 
                            width="100%" 
                            height={36} 
                            sx={{ borderRadius: 20, mt: 2 }} 
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : empty ? (
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  minHeight: '50vh',
                  textAlign: 'center',
                  gap: 2
                }}>
                  <Typography variant="h5" color="text.secondary">
                    Welcome to Siftsy
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Get started by creating your first team
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setCreateTeamOpen(true)}
                    sx={{ mt: 2, borderRadius: 2 }}
                  >
                    Create Team
                  </Button>
                </Box>
              ) : (
                <>
                  <Grid container spacing={3}>
                    {filteredTeams.map((team) => (
                      team && team.id ? (
                        <Grid item xs={12} sm={6} md={4} key={team.id}>
                          <Card
                            elevation={0}
                            sx={{
                              borderRadius: 3,
                              p: 3,
                              height: 200,
                              display: 'flex',
                              flexDirection: 'column',
                              transition: 'all 0.3s ease',
                              border: '1px solid #eee',
                              '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                                borderColor: 'primary.main',
                              },
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <Avatar sx={{ bgcolor: 'primary.main', mr: 2, flexShrink: 0 }}>
                                  {team.name ? team.name.charAt(0).toUpperCase() : '?'}
                                </Avatar>
                                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 0 }}>
                                  <Typography 
                                    variant="h6" 
                                    sx={{ 
                                      fontWeight: 600,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap'
                                    }}
                                  >
                                    {team.name || 'Unnamed Team'}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    {teammateData[team.id]?.length || 0} {(teammateData[team.id]?.length || 0) === 1 ? 'member' : 'members'}
                                  </Typography>
                                </Box>
                              </Box>
                              {team.plan?.name && (
                                <Box sx={{ pl: 7 }}>
                                  <Chip 
                                    size="small" 
                                    label={team.plan.name}
                                    color={team.plan.name.toLowerCase().includes('pro') ? 'primary' : 'default'}
                                  />
                                </Box>
                              )}
                            </Box>

                            <Box sx={{ flexGrow: 1 }} />

                            <Button
                              variant="contained"
                              color="primary"
                              endIcon={<OpenInNew />}
                              fullWidth
                              sx={{ borderRadius: 20, mt: 2, flexShrink: 0 }}
                              onClick={() => navigate(`/team/${team.id}`)}
                            >
                              View Team
                            </Button>
                          </Card>
                        </Grid>
                      ) : null
                    ))}
                  </Grid>
                </>
              )}
            </Box>
          </Box>

          <Footer />
        </div>
        
        <CreateTeamModal
          open={createTeamOpen}
          onClose={() => setCreateTeamOpen(false)}
          onSuccess={handleTeamCreated}
        />
      </ThemeProvider>
    );
  }

function CreateTeamAndLinkUsers(props) {
  const Functions = HelperFunctions();

  const [teamName, setTeamName] = useState('');
  const [users, setUsers] = useState([{ name: '', email: '', role: 'admin', status: 'active' }]);
  const { teamId } = props;

  const handleTeamNameChange = (e) => {
    setTeamName(e.target.value);
  };

  const handleUserNameChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index].name = e.target.value;
    setUsers(newUsers);
  };

  const handleUserEmailChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index].email = e.target.value;
    setUsers(newUsers);
  };

  const handleUserRoleChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index].role = e.target.value;
    setUsers(newUsers);
  };

  const handleUserStatusChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index].status = e.target.value;
    setUsers(newUsers);
  };

  const addNewUserField = () => {
    setUsers([...users, { name: '', email: '', role: '', status: '' }]);
  };

  const handleCreateTeamAndUsers = async () => {
    try {
      // Create a new team
      // const teamResponse = await Functions.createTeam(teamName);
      // const createdTeamId = teamResponse.data.id;
      // setTeamId(createdTeamId);

      alert(teamId);

      for (const user of users) {
        // Create each user
        let userData = await Functions.fetchUser(user.email);

        console.log("userData", userData);

        if (!userData) {
          const userResponse = await Functions.createUser(user.email, user.name);
          userData = userResponse;
        }
        await Functions.linkUserTeam(userData.id, teamId);
   
      }

      

      alert('Team created, users created, and linked successfully!');
    } catch (error) {
      console.error('Error creating team, users, or linking:', error);
      alert('Failed to create team, users, or link them.');
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      {/* <Typography variant="h4" gutterBottom>
        Create New Team
      </Typography>
      <TextField
        label="Team Name"
        variant="outlined"
        value={teamName}
        onChange={handleTeamNameChange}
        fullWidth
        margin="normal"
      /> */}
      <Typography variant="h5" gutterBottom>
        Link Users to Team
      </Typography>
      {users.map((user, index) => (
        <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={3}>
            <TextField
              label="User Name"
              variant="outlined"
              value={user.name}
              onChange={(e) => handleUserNameChange(e, index)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="User Email"
              variant="outlined"
              value={user.email}
              onChange={(e) => handleUserEmailChange(e, index)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Role"
              variant="outlined"
              value={user.role}
              onChange={(e) => handleUserRoleChange(e, index)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Status"
              variant="outlined"
              value={user.status}
              onChange={(e) => handleUserStatusChange(e, index)}
              fullWidth
            />
          </Grid>
        </Grid>
      ))}
      <Button variant="contained" color="primary" onClick={addNewUserField}>
        Add Another User
      </Button>
      <Box sx={{ marginTop: 4 }}>
        <Button variant="contained" color="secondary" onClick={handleCreateTeamAndUsers}>
          Create Team and Link Users
        </Button>
      </Box>
    </Box>
  );
}
