import {
  Box,
  Grid,
  Typography,
  Card,
  ThemeProvider,
  ButtonBase,
  AppBar,
  Toolbar,
  Avatar,
  Button,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import {
  Add,
  DoneAllRounded,
  GroupOutlined,
  OpenInNew,
  Search,
  MoreVert,
  PersonAdd,
  Settings,
  PhotoCamera,
  Group,
  SwapHoriz,
} from "@mui/icons-material";
import React, { useState, useCallback, useMemo, useRef } from "react";
import { siftsyTheme } from "./siftsytheme";
import { useUser } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";
import HelperFunctions from "./HelperFunctions";
import Footer from "./Footer";
import { UsageComponent } from "./TeamSettingsModal";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

// Add new styles for enhanced components
const modalStyles = {
  paper: {
    borderRadius: 3,
    boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ddd',
      borderRadius: 4,
    },
  },
  title: {
    borderBottom: '1px solid #eee',
    padding: '20px 24px',
  },
  content: {
    padding: '24px !important',
  },
  actions: {
    padding: '16px 24px',
    borderTop: '1px solid #eee',
  },
};

const memberCardStyles = {
  root: {
    border: '1px solid #eee',
    borderRadius: 2,
    backgroundColor: '#fff',
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
      borderColor: '#4728c4',
    },
  },
  avatar: {
    background: (theme) => theme.palette.primary.main,
    fontWeight: 600,
  },
  roleChip: {
    height: 24,
    fontSize: '0.75rem',
    fontWeight: 600,
  },
};

// Add getRoleColor function
const getRoleColor = (role) => {
  switch (role) {
    case 'admin':
      return '#4728c4';
    case 'analyst':
      return '#2e7d32';
    case 'reader':
      return '#ed6c02';
    default:
      return '#757575';
  }
};

// Add role definitions
const roleDefinitions = {
  admin: "Can create teams, add and remove members, and have full analyst access in a team.",
  analyst: "Can add posts, accounts, campaigns, content tags, and have full access to a team",
  reader: "Have read-only access to a team. Can view and search through analytics, but cannot add posts, accounts, campaigns, or content tags."
};

function CreateEnterpriseTeamModal({ open, onClose, onSuccess, enterpriseMembers }) {
  const [teamName, setTeamName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [newTeamId, setNewTeamId] = useState(null);
  const Functions = HelperFunctions();
  const { user } = useUser();
  const navigate = useNavigate();

  const handleCreateTeam = async () => {
    const trimmedName = teamName.trim();
    if (!trimmedName) {
      setError('Team name is required');
      return;
    }

    if (trimmedName.length > 100) {
      setError('Team name must be 100 characters or less');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Create the team
      const newTeam = await Functions.createTeam(trimmedName);
      
      if (newTeam) {
        // Link selected members to the team
        for (const member of selectedMembers) {
          await Functions.linkUserTeam(member.id, newTeam.id);
        }
        
        setNewTeamId(newTeam.id);
        setSuccess(true);
        onSuccess();
        setTeamName('');
        setSelectedMembers([]);
      } else {
        setError('Failed to create team. Please try again.');
      }
    } catch (error) {
      console.error('Error creating team:', error);
      setError('Failed to create team. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleMemberToggle = (member) => {
    setSelectedMembers(prev => {
      const isSelected = prev.some(m => m.id === member.id);
      if (isSelected) {
        return prev.filter(m => m.id !== member.id);
      } else {
        return [...prev, member];
      }
    });
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
      setNewTeamId(null);
      setSelectedMembers([]);
    }, 300);
  };

  const goToNewTeam = () => {
    handleClose();
    navigate(`/team/${newTeamId}`);
  };

  return (
    <Dialog 
      open={open} 
      onClose={loading ? undefined : handleClose}
      maxWidth="sm" 
      fullWidth
    >
      {success ? (
        <>
          <DialogContent>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              py: 3,
              gap: 2
            }}>
              <Box sx={{ 
                width: 40, 
                height: 40, 
                borderRadius: '50%', 
                bgcolor: 'success.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 1
              }}>
                <DoneAllRounded sx={{ color: 'white' }} />
              </Box>
              <Typography variant="h6" align="center">
                Team Created Successfully!
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                Your new team is ready to go
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2, pt: 0 }}>
            <Button 
              onClick={handleClose}
              sx={{ borderRadius: 2 }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={goToNewTeam}
              sx={{ borderRadius: 2 }}
              endIcon={<OpenInNew />}
            >
              Go to Team
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Create New Team</DialogTitle>
          <DialogContent>
            <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
              <TextField
                autoFocus
                label="Team Name"
                fullWidth
                value={teamName}
                onChange={(e) => {
                  setTeamName(e.target.value);
                  setError('');
                }}
                error={!!error}
                helperText={error || `${teamName.length}/100 characters`}
                disabled={loading}
                inputProps={{
                  maxLength: 100
                }}
                placeholder="Enter your team name"
              />

              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Select Team Members
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: 1,
                  p: 2,
                  border: '1px solid #ddd',
                  borderRadius: 1,
                  minHeight: 100
                }}>
                  {enterpriseMembers.map((member) => (
                    <Chip
                      key={member.id}
                      label={member.email}
                      onClick={() => handleMemberToggle(member)}
                      color={selectedMembers.some(m => m.id === member.id) ? "primary" : "default"}
                      icon={selectedMembers.some(m => m.id === member.id) ? <DoneAllRounded /> : undefined}
                      sx={{ 
                        m: 0.5,
                        '& .MuiChip-icon': {
                          color: 'inherit',
                          ml: 1,
                          order: 1
                        },
                        '& .MuiChip-label': {
                          order: 0
                        }
                      }}
                    />
                  ))}
                </Box>
                <Typography variant="caption" color="text.secondary">
                  {selectedMembers.length} members selected
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleClose} 
              disabled={loading}
              sx={{ borderRadius: 2 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateTeam}
              variant="contained"
              disabled={loading || !teamName.trim()}
              sx={{ borderRadius: 2 }}
              startIcon={loading ? <CircularProgress size={20} /> : <Add />}
            >
              Create Team
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

function ManageEnterpriseMembersModal({ open, onClose, enterpriseMembers, onMemberAdd, onMemberRemove, teamId }) {
  const [newEmail, setNewEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState('reader');
  const [error, setError] = useState('');
  const { user } = useUser();
  const Functions = HelperFunctions();

  const handleAddMember = async () => {
    if (!newEmail) {
      setError('Email is required');
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
      setError('Invalid email format');
      return;
    }

    // Create new member object
    const newMember = {
      id: Date.now().toString(), // Temporary ID for demo
      email: newEmail,
      role: selectedRole
    };

    // In production, make API call to add member
    await Functions.addMemberToTeam(teamId, newEmail, selectedRole);

    onMemberAdd(newMember);
    setNewEmail('');
    setSelectedRole('reader');
    setError('');
    
  };

  const handleRoleChange = async (memberId, newRole) => {
    try {
      // TODO: Implement role change functionality
      // await Functions.updateMemberRole(memberId, newRole);
      console.log('Role updated:', memberId, newRole);
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: modalStyles.paper }}
    >
      <DialogTitle sx={modalStyles.title}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <PersonAdd sx={{ color: 'primary.main' }} />
          <Typography variant="h6">Manage Members</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={modalStyles.content}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            alignItems: 'flex-start',
            backgroundColor: '#f8f9fa',
            p: 2,
            borderRadius: 2,
          }}>
            <TextField
              label="Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              error={Boolean(error)}
              helperText={error}
              size="small"
              sx={{ flexGrow: 1 }}
              InputProps={{
                sx: { backgroundColor: '#fff' }
              }}
            />
            <Tooltip 
              title={roleDefinitions[selectedRole]}
              placement="top"
              arrow
            >
              <TextField
                select
                label="Role"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                size="small"
                sx={{ minWidth: 120 }}
                InputProps={{
                  sx: { backgroundColor: '#fff', cursor: 'help' }
                }}
              >
                <MenuItem value="reader">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#ed6c02' }} />
                    Reader
                  </Box>
                </MenuItem>
                <MenuItem value="analyst">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#2e7d32' }} />
                    Analyst
                  </Box>
                </MenuItem>
                <MenuItem value="admin">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#4728c4' }} />
                    Admin
                  </Box>
                </MenuItem>
              </TextField>
            </Tooltip>
            <Button
              variant="contained"
              onClick={handleAddMember}
              sx={{ borderRadius: 2, px: 3 }}
            >
              Add Member
            </Button>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Group />
              Enterprise Members ({enterpriseMembers.length})
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {enterpriseMembers.map((member) => (
                <Box
                  key={member.id}
                  sx={{
                    ...memberCardStyles.root,
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar sx={memberCardStyles.avatar}>
                      {(member.email || member.name || '?')[0].toUpperCase()}
                    </Avatar>
                    <Box>
                      <Typography variant="subtitle1" fontWeight={500}>
                        {member.name || member.email || 'Unknown Member'}
                      </Typography>
                      <Tooltip 
                        title={roleDefinitions[member.role || 'reader']}
                        placement="right"
                        arrow
                      >
                        <Chip
                          label={(member.role || 'reader').charAt(0).toUpperCase() + (member.role || 'reader').slice(1)}
                          size="small"
                          sx={{
                            ...memberCardStyles.roleChip,
                            backgroundColor: getRoleColor(member.role || 'reader') + '10',
                            color: getRoleColor(member.role || 'reader'),
                            border: `1px solid ${getRoleColor(member.role || 'reader')}50`,
                            cursor: 'help'
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {member.email !== user?.primaryEmailAddress?.emailAddress && (
                      <>
                        <Tooltip 
                          title={roleDefinitions[member.role || 'reader']}
                          placement="top"
                          arrow
                        >
                          <TextField
                            select
                            size="small"
                            value={member.role}
                            onChange={(e) => handleRoleChange(member.id, e.target.value)}
                            sx={{ minWidth: 120 }}
                            InputProps={{
                              sx: { cursor: 'help' }
                            }}
                          >
                            <MenuItem value="reader">
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#ed6c02' }} />
                                Reader
                              </Box>
                            </MenuItem>
                            <MenuItem value="analyst">
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#2e7d32' }} />
                                Analyst
                              </Box>
                            </MenuItem>
                            <MenuItem value="admin">
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#4728c4' }} />
                                Admin
                              </Box>
                            </MenuItem>
                          </TextField>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => onMemberRemove(member.id)}
                          size="small"
                          sx={{ 
                            borderRadius: 2,
                            borderColor: 'error.light',
                            '&:hover': {
                              backgroundColor: 'error.light',
                              color: 'white',
                            }
                          }}
                        >
                          Remove
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={modalStyles.actions}>
        <Button 
          onClick={onClose} 
          sx={{ 
            borderRadius: 2,
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'grey.100',
            }
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function EnterpriseSettingsModal({ open, onClose, enterpriseTeam, onSave }) {
  const [loading, setLoading] = useState(false);
  const [enterpriseIcon, setEnterpriseIcon] = useState(enterpriseTeam?.icon || null);
  const [previewIcon, setPreviewIcon] = useState(enterpriseTeam?.icon || null);
  const [iconType, setIconType] = useState(enterpriseTeam?.iconType || 'square');
  const [selectedTab, setSelectedTab] = useState(0);
  const [enterpriseName, setEnterpriseName] = useState(enterpriseTeam?.name || '');
  const [usageData, setUsageData] = useState({
    totalComments: 0,
    MAAs: [],
    totalAccounts: 0,
    postsCompleted: 0,
    plan: {
      type: "",
      comments: 0,
      postsUploaded: 0,
      postsCompleted: 0,
    }
  });
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate()));
  const [endDate, setEndDate] = useState(new Date());
  const fileInputRef = useRef(null);
  const Functions = HelperFunctions();

  React.useEffect(() => {
    async function fetchData() {
      if (enterpriseTeam?.id) {
        let planStart = enterpriseTeam.plan && enterpriseTeam.plan.startDate 
          ? new Date(enterpriseTeam.plan.startDate) 
          : new Date(new Date().getFullYear(), new Date().getMonth(), 1);

        let start = new Date(new Date().getFullYear(), 
          planStart.getDate() > new Date().getDate() ? new Date().getMonth() - 1 : new Date().getMonth(), 
          planStart.getDate()).toISOString();
        
        let end = new Date(new Date().getFullYear(),  
          planStart.getDate() > new Date().getDate() ? new Date().getMonth() : new Date().getMonth() + 1, 
          planStart.getDate() - 1).toISOString();

        setStartDate(start);
        setEndDate(end);

        try {
          const data = await Functions.getTeamUsageData(enterpriseTeam.id, start, end);
          setUsageData(data);
        } catch (error) {
          console.error('Error fetching usage data:', error);
        }
      }
    }
    fetchData();
  }, [enterpriseTeam?.id]);

  const goToPreviousPeriod = () => {
    const start = new Date(startDate);
    setEndDate(new Date(start.getFullYear(), start.getMonth(), start.getDate() - 1));
    setStartDate(new Date(start.getFullYear(), start.getMonth() - 1, start.getDate()));
  };

  const goToNextPeriod = () => {
    const end = new Date(endDate);
    setStartDate(new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1));
    setEndDate(new Date(end.getFullYear(), end.getMonth() + 1, end.getDate()));
  };

  const handleOpenStripePortal = () => {
    window.open(`https://billing.stripe.com/p/login/6oE16iauBg0jfwQ144?enterpriseId=${enterpriseTeam.id}`, '_blank');
  };

  const handleIconUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Preview the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewIcon(reader.result);
    };
    reader.readAsDataURL(file);

    setLoading(true);
    try {
      // Upload the icon to S3 and update team data
      const uploadedUrl = await Functions.uploadTeamIcon(enterpriseTeam.id, file, iconType);
      setEnterpriseIcon(uploadedUrl);
      onSave({ ...enterpriseTeam, icon: uploadedUrl, iconType });
    } catch (error) {
      console.error('Error uploading icon:', error);
      // You might want to add error handling UI here
    } finally {
      setLoading(false);
    }
  };

  const handleIconTypeChange = (newType) => {
    setIconType(newType);
    if (enterpriseIcon) {
      onSave({ ...enterpriseTeam, icon: enterpriseIcon, iconType: newType });
    }
  };

  const handleEnterpriseNameChange = (event) => {
    setEnterpriseName(event.target.value);
  };

  const handleSaveEnterpriseName = () => {
    if (enterpriseName.trim() !== enterpriseTeam.name) {
      onSave({ ...enterpriseTeam, name: enterpriseName.trim() });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
            <ButtonBase 
              onClick={() => setSelectedTab(0)} 
              sx={{
                backgroundColor: 'white !important',
                borderRadius: 4,
                padding: '10px 30px',
                border: `1px solid ${selectedTab === 0 ? '#4728c4' : '#ddd'}`
              }}
            >
              <Typography variant="body2" fontWeight={700} color={selectedTab === 0 ? 'primary' : 'textSecondary'}>
                Overview
              </Typography>
            </ButtonBase>
            <ButtonBase 
              onClick={() => setSelectedTab(1)} 
              sx={{
                backgroundColor: 'white !important',
                borderRadius: 4,
                padding: '10px 30px',
                border: `1px solid ${selectedTab === 1 ? '#4728c4' : '#ddd'}`
              }}
            >
              <Typography variant="body2" fontWeight={700} color={selectedTab === 1 ? 'primary' : 'textSecondary'}>
                Branding
              </Typography>
            </ButtonBase>
            <ButtonBase 
              onClick={() => setSelectedTab(2)} 
              sx={{
                backgroundColor: 'white !important',
                borderRadius: 4,
                padding: '10px 30px',
                border: `1px solid ${selectedTab === 2 ? '#4728c4' : '#ddd'}`
              }}
            >
              <Typography variant="body2" fontWeight={700} color={selectedTab === 2 ? 'primary' : 'textSecondary'}>
                Usage & Billing
              </Typography>
            </ButtonBase>
          </Box>

          {selectedTab === 0 && (
            <Box sx={{ p: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Enterprise Overview
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Manage your enterprise settings and information.
              </Typography>

              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Enterprise Name
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={enterpriseName}
                onChange={handleEnterpriseNameChange}
                onBlur={handleSaveEnterpriseName}
                sx={{
                  backgroundColor: '#ffffff',
                  borderRadius: 1,
                  mb: 1,
                }}
              />
            </Box>
          )}

          {selectedTab === 1 && (
            <Box sx={{ p: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1">
                  Enterprise Icon
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    size="small"
                    variant={iconType === 'square' ? 'contained' : 'outlined'}
                    onClick={() => handleIconTypeChange('square')}
                    sx={{ minWidth: 100 }}
                  >
                    Square
                  </Button>
                  <Button
                    size="small"
                    variant={iconType === 'wordmark' ? 'contained' : 'outlined'}
                    onClick={() => handleIconTypeChange('wordmark')}
                    sx={{ minWidth: 100 }}
                  >
                    Wordmark
                  </Button>
                </Box>
              </Box>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 2 
              }}>
                <Box
                  sx={{
                    width: iconType === 'wordmark' ? 240 : 120,
                    height: 120,
                    borderRadius: iconType === 'wordmark' ? 2 : '50%',
                    border: '2px dashed #ddd',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                    transition: 'all 0.3s ease',
                    '&:hover .upload-overlay': {
                      opacity: 1,
                    },
                  }}
                >
                  {previewIcon ? (
                    <img
                      src={previewIcon}
                      alt="Enterprise icon"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: iconType === 'wordmark' ? 'contain' : 'cover',
                      }}
                    />
                  ) : (
                    <Typography variant="body2" color="text.secondary" align="center">
                      {iconType === 'wordmark' ? 'Upload wordmark' : 'Upload square icon'}
                    </Typography>
                  )}
                  <Box
                    className="upload-overlay"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: 0,
                      transition: 'opacity 0.2s',
                      cursor: 'pointer',
                    }}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <PhotoCamera sx={{ color: 'white' }} />
                  </Box>
                </Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleIconUpload}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                  <Button
                    variant="outlined"
                    onClick={() => fileInputRef.current?.click()}
                    startIcon={<PhotoCamera />}
                    disabled={loading}
                  >
                    {loading ? 'Uploading...' : 'Upload Icon'}
                  </Button>
                  <Typography variant="caption" color="text.secondary">
                    {iconType === 'wordmark' 
                      ? 'Recommended size: 240x120px, PNG or SVG with transparent background'
                      : 'Recommended size: 120x120px, PNG or SVG with transparent background'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {selectedTab === 2 && (
            <Box sx={{ p: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">
                    Usage & Billing
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenStripePortal}
                    sx={{ borderRadius: 2 }}
                    endIcon={<OpenInNew />}
                  >
                    Manage Billing
                  </Button>
                </Box>

                <UsageComponent
                  teamData={enterpriseTeam}
                  usageData={usageData}
                  startDate={startDate}
                  endDate={endDate}
                  isMobile={false}
                  goToPreviousPeriod={goToPreviousPeriod}
                  goToNextPeriod={goToNextPeriod}
                  handleOpenStripePortal={handleOpenStripePortal}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function TeamMembersModal({ open, onClose, team, allTeams, isAdmin, enterpriseMembers, setEnterpriseMembers, setTeamSpaces }) {
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [manageMembersOpen, setManageMembersOpen] = useState(false);

  // Filter out enterprise members who are already in the team
  const availableMembers = useMemo(() => 
    enterpriseMembers.filter(member => 
      !team.members?.some(teamMember => teamMember.id === member.id)
    ),
    [enterpriseMembers, team.members]
  );

  const handleAddMembers = async (selectedMembers) => {
    try {
      // In production, make API call to add members
      // await Functions.addMembersToTeam(team.id, selectedMembers);
      
      // Update local state
      setTeamSpaces(prev => prev.map(t => {
        if (t.id === team.id) {
          return {
            ...t,
            members: [...(t.members || []), ...selectedMembers]
          };
        }
        return t;
      }));
      
      setAddMemberOpen(false);
    } catch (error) {
      console.error('Error adding members:', error);
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      // In production, make API call to remove member
      // await Functions.removeMemberFromTeam(team.id, memberId);
      
      // Update local state
      setTeamSpaces(prev => prev.map(t => {
        if (t.id === team.id) {
          return {
            ...t,
            members: t.members.filter(m => m.id !== memberId)
          };
        }
        return t;
      }));
    } catch (error) {
      console.error('Error removing member:', error);
    }
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{ sx: modalStyles.paper }}
      >
        <DialogTitle sx={modalStyles.title}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Group sx={{ color: 'primary.main' }} />
              <Typography variant="h6">Team Members - {team.name}</Typography>
            </Box>
            {isAdmin && (
              <Button
                variant="contained"
                onClick={() => setAddMemberOpen(true)}
                startIcon={<PersonAdd />}
                sx={{ borderRadius: 2 }}
              >
                Add Members
              </Button>
            )}
          </Box>
        </DialogTitle>
        <DialogContent sx={modalStyles.content}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {team.members?.map((member) => (
              <Box
                key={member.id}
                sx={memberCardStyles.root}
              >
                <ListItem
                  secondaryAction={
                    isAdmin && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveMember(member.id)}
                        sx={{ borderRadius: 2 }}
                      >
                        Remove
                      </Button>
                    )
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={memberCardStyles.avatar}>
                      {(member.email || member.name || '?')[0].toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" fontWeight={500}>
                        {member.name || member.email || 'Unknown Member'}
                      </Typography>
                    }
                    secondary={
                      <Tooltip 
                        title={roleDefinitions[member.role || 'reader']}
                        placement="right"
                        arrow
                      >
                        <Chip
                          label={(member.role || 'reader').charAt(0).toUpperCase() + (member.role || 'reader').slice(1)}
                          size="small"
                          sx={{
                            ...memberCardStyles.roleChip,
                            backgroundColor: getRoleColor(member.role || 'reader') + '10',
                            color: getRoleColor(member.role || 'reader'),
                            border: `1px solid ${getRoleColor(member.role || 'reader')}50`,
                            cursor: 'help'
                          }}
                        />
                      </Tooltip>
                    }
                  />
                </ListItem>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={modalStyles.actions}>
          <Button onClick={onClose} sx={{ borderRadius: 2 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addMemberOpen}
        onClose={() => setAddMemberOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Team Members</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Select enterprise members to add to {team.name}
              </Typography>
              <Button
                variant="outlined"
                startIcon={<PersonAdd />}
                onClick={() => {
                  setAddMemberOpen(false);
                  setManageMembersOpen(true);
                }}
                sx={{ borderRadius: 2 }}
              >
                Manage Users
              </Button>
            </Box>
            <List>
              {availableMembers.map((member) => (
                <ListItem
                  key={member.id}
                  secondaryAction={
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handleAddMembers([member])}
                    >
                      Add
                    </Button>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={memberCardStyles.avatar}>
                      {(member.email || member.name || '?')[0].toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={member.name || member.email || 'Unknown Member'}
                    secondary={
                      <Tooltip 
                        title={roleDefinitions[member.role || 'reader']}
                        placement="right"
                        arrow
                      >
                        <Chip
                          label={(member.role || 'reader').charAt(0).toUpperCase() + (member.role || 'reader').slice(1)}
                          size="small"
                          sx={{
                            ...memberCardStyles.roleChip,
                            backgroundColor: getRoleColor(member.role || 'reader') + '10',
                            color: getRoleColor(member.role || 'reader'),
                            border: `1px solid ${getRoleColor(member.role || 'reader')}50`,
                            cursor: 'help'
                          }}
                        />
                      </Tooltip>
                    }
                  />
                </ListItem>
              ))}
              {availableMembers.length === 0 && (
                <Typography variant="body2" color="text.secondary" sx={{ p: 2, textAlign: 'center' }}>
                  All enterprise members are already in this team
                </Typography>
              )}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddMemberOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <ManageEnterpriseMembersModal
        teamId={team.id}
        open={manageMembersOpen}
        onClose={() => setManageMembersOpen(false)}
        enterpriseMembers={enterpriseMembers}
        onMemberAdd={(newMember) => {
          handleAddMembers([newMember]);
        }}
        onMemberRemove={(memberId) => {
          setEnterpriseMembers(prev => prev.filter(m => m.id !== memberId));
        }}
      />
    </>
  );
}

export default function EnterpriseDashboard() {
  const { isLoaded, isSignedIn, user } = useUser();
  const { enterpriseId } = useParams();
  const [teamSpaces, setTeamSpaces] = useState([]);
  const [enterpriseMembers, setEnterpriseMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [createTeamOpen, setCreateTeamOpen] = useState(false);
  const [manageMembersOpen, setManageMembersOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [enterpriseSettingsOpen, setEnterpriseSettingsOpen] = useState(false);
  const [teamMembersModalOpen, setTeamMembersModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const navigate = useNavigate();
  const Functions = HelperFunctions();

  // Update sample data with roles
  const sampleEnterpriseMembers = [
    { id: '1', email: 'leon.lyakovetsky@gmail.com', name: 'Leon Lyakovetsky', role: 'admin' },
    { id: '2', email: 'mike@company.com', name: 'Mike Chen', role: 'analyst' },
    { id: '3', email: 'alex@company.com', name: 'Alex Rodriguez', role: 'analyst' },
    { id: '4', email: 'emma@company.com', name: 'Emma Wilson', role: 'reader' },
    { id: '5', email: 'james@company.com', name: 'James Lee', role: 'reader' },
    { id: '6', email: 'lisa@company.com', name: 'Lisa Brown', role: 'analyst' },
    { id: '7', email: 'david@company.com', name: 'David Miller', role: 'reader' },
    { id: '8', email: 'sophia@company.com', name: 'Sophia Garcia', role: 'analyst' },
    { id: '9', email: 'ryan@company.com', name: 'Ryan Taylor', role: 'reader' },
    { id: '10', email: 'olivia@company.com', name: 'Olivia Martinez', role: 'analyst' }
  ];

  const sampleTeamSpaces = [
    {
      id: "enterprise",
      name: 'Lyft',
      members: sampleEnterpriseMembers,
      plan: { name: 'Enterprise', teamMembers: 50 },
      isEnterprise: true
    },
    {
      id: "1",
      name: 'Marketing Team',
      members: [
        { id: '1', email: 'sarah@company.com', name: 'Sarah Johnson' },
        { id: '2', email: 'mike@company.com', name: 'Mike Chen' },
        { id: '3', email: 'alex@company.com', name: 'Alex Rodriguez' }
      ],
      isEnterprise: false,
      belongsToEnterprise: true,
      enterpriseId: "enterprise",
      plan: { name: 'Enterprise', teamMembers: 10 }
    },
    {
      id: '2',
      name: 'Product Development',
      members: [
        { id: '4', email: 'emma@company.com', name: 'Emma Wilson' },
        { id: '5', email: 'james@company.com', name: 'James Lee' },
        { id: '6', email: 'lisa@company.com', name: 'Lisa Brown' },
        { id: '2', email: 'mike@company.com', name: 'Mike Chen' }
      ],
      plan: { name: 'Enterprise', teamMembers: 10 }
    },
    {
      id: '3',
      name: 'Sales & Growth',
      members: [
        { id: '7', email: 'david@company.com', name: 'David Miller' },
        { id: '8', email: 'sophia@company.com', name: 'Sophia Garcia' },
        { id: '1', email: 'sarah@company.com', name: 'Sarah Johnson' }
      ],
      plan: { name: 'Enterprise', teamMembers: 10 }
    },
    {
      id: '4',
      name: 'Customer Success',
      members: [
        { id: '9', email: 'ryan@company.com', name: 'Ryan Taylor' },
        { id: '10', email: 'olivia@company.com', name: 'Olivia Martinez' },
        { id: '6', email: 'lisa@company.com', name: 'Lisa Brown' }
      ],
      plan: { name: 'Enterprise', teamMembers: 10 }
    }
  ];

  const fetchData = useCallback(async () => {
    if (!user) return;
    
    setLoading(true);
    try {
      // For testing: Use sample data instead of API calls
      // Comment these lines and uncomment the API calls when ready for production
      // setTeamSpaces(sampleTeamSpaces);
      // setEnterpriseMembers(sampleEnterpriseMembers);
      
      // Production API calls (commented out for testing)
      const teams = await Functions.getUserTeamData(user.id);
      setTeamSpaces(teams || []);
      const members = await Functions.getTeammateData(user.id);
      setEnterpriseMembers(members || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  React.useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user, fetchData]);

  const handleTeamCreated = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const handleAddMember = async (newMember) => {
    try {
      // In production, make API call to add member
      // await Functions.addMemberToEnterprise(newMember);
      
      // Update local state
      setEnterpriseMembers(prev => [...prev, newMember]);
      
      // If the member should be added to a team immediately, add them
      if (selectedTeam) {
        setTeamSpaces(prev => prev.map(team => {
          if (team.id === selectedTeam.id) {
            return {
              ...team,
              members: [...(team.members || []), newMember]
            };
          }
          return team;
        }));
      }
    } catch (error) {
      console.error('Error adding member:', error);
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      await Functions.removeMemberFromTeam(user.id, memberId);
      setEnterpriseMembers(prev => prev.filter(member => member.id !== memberId));
    } catch (error) {
      console.error('Error removing member:', error);
    }
  };

  const filteredTeams = useMemo(() => {
    if (!teamSpaces || !Array.isArray(teamSpaces)) return [];
    return teamSpaces
      .filter(team => 
        team && 
        team.name && 
        !team.isEnterprise && // Filter out the enterprise team
        team.name.toLowerCase().includes((searchQuery || '').toLowerCase())
      );
  }, [teamSpaces, searchQuery]);

  const enterpriseTeam = useMemo(() => 
    teamSpaces.find(team => team.isEnterprise),
    [teamSpaces]
  );

  const handleEnterpriseSettingsSave = async (updatedEnterprise) => {
    try {
      // Update the enterprise team in the list
      setTeamSpaces(prev => prev.map(team => 
        team.isEnterprise ? updatedEnterprise : team
      ));
      setEnterpriseSettingsOpen(false);
    } catch (error) {
      console.error('Error saving enterprise settings:', error);
    }
  };

  // Add isAdmin check
  const isAdmin = useMemo(() => {
    const userEmail = user?.primaryEmailAddress?.emailAddress;
    return enterpriseMembers.some(
      member => member.email === userEmail && member.role === 'admin'
    );
  }, [user, enterpriseMembers]);

  return (
    <ThemeProvider theme={siftsyTheme}>
      <div style={{
        backgroundColor: "#f7f8fa",
        width: "100%",
        minHeight: '100vh',
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}>
        <AppBar
          position="static"
          elevation={0}
          sx={{ background: "#fff", borderBottom: "1px solid #ddd" }}
        >
          <Toolbar>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: 1200,
              width: "100%",
              margin: "0 auto",
              gap: 2
            }}>
              <Box>
                <ButtonBase>
                  <img
                    src={"/siftsy_logo.png"}
                    alt="logo"
                    style={{ width: 80, height: "auto", padding: "5px" }}
                  />
                </ButtonBase>
              </Box>
              {enterpriseTeam?.icon && (
                 <Typography variant="h6" sx={{ flexGrow: 1, color: 'text.primary' }}>
                x
               </Typography>
              )}
              {enterpriseTeam?.icon && (
                <Box sx={{ 
                  height: 32,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <img
                    src={enterpriseTeam.icon}
                    alt={`${enterpriseTeam.name} icon`}
                    style={{
                      height: '100%',
                      width: 'auto',
                      maxWidth: enterpriseTeam.iconType === 'wordmark' ? '120px' : '32px',
                      objectFit: enterpriseTeam.iconType === 'wordmark' ? 'contain' : 'cover',
                    }}
                  />
                </Box>
              )}
              <Typography variant="h6" sx={{ flexGrow: 1, color: 'text.primary', display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                {enterpriseTeam?.name || 'Enterprise'}
              </Typography>
              <Box>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <Cog6ToothIcon style={{ width: 24, height: 24 }} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.08))',
                      mt: 1.5,
                      borderRadius: 2,
                      border: '1px solid #eee',
                      minWidth: 220,
                      '& .MuiMenuItem-root': {
                        px: 2,
                        py: 1.5,
                        borderRadius: 1,
                        mx: 0.5,
                        mb: 0.5,
                        '&:hover': {
                          backgroundColor: 'rgba(71, 40, 196, 0.04)',
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem 
                    onClick={() => {
                      setAnchorEl(null);
                      setManageMembersOpen(true);
                    }}
                    sx={{
                      color: 'text.primary',
                      fontWeight: 500,
                    }}
                  >
                    <PersonAdd sx={{ mr: 1.5, color: 'primary.main', fontSize: 20 }} />
                    Manage Members
                  </MenuItem>
                  <MenuItem 
                    onClick={() => {
                      setAnchorEl(null);
                      setEnterpriseSettingsOpen(true);
                    }}
                    sx={{
                      color: 'text.primary',
                      fontWeight: 500,
                    }}
                  >
                    <Settings sx={{ mr: 1.5, color: 'primary.main', fontSize: 20 }} />
                    Enterprise Settings
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Box sx={{
          maxWidth: 1200,
          width: "100%",
          margin: "0 auto",
          mt: 2,
          px: 4,
          flex: 1,
        }}>
          <Box sx={{ mb: 4 }}>
            <Card
              elevation={0}
              sx={{
                borderRadius: 3,
                border: "1px solid #eee",
                background: "#fff",
              }}
            >
              <Box sx={{ 
                p: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                flexWrap: "wrap"
              }}>
                <Box sx={{ 
                  display: "flex", 
                  alignItems: "center",
                  flex: 1,
                  minWidth: 300,
                }}>
                  <Search sx={{ color: "text.secondary", mr: 1 }} />
                  <TextField
                    size="small"
                    placeholder={`Search ${filteredTeams.length} ${filteredTeams.length === 1 ? 'team' : 'teams'}...`}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={loading}
                  />
                </Box>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => setCreateTeamOpen(true)}
                  sx={{ borderRadius: 2, flexShrink: 0 }}
                >
                  New Team
                </Button>
              </Box>
            </Card>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3}>
              {filteredTeams.map((team) => (
                team && team.id ? (
                  <Grid item xs={12} sm={6} md={4} key={team.id}>
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: 3,
                        p: 3,
                        height: 200,
                        display: 'flex',
                        flexDirection: 'column',
                        transition: 'all 0.3s ease',
                        border: '1px solid #eee',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                          borderColor: 'primary.main',
                        },
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Avatar sx={{ bgcolor: team.isEnterprise ? 'secondary.main' : 'primary.main', mr: 2 }}>
                            {team.name ? team.name.charAt(0).toUpperCase() : '?'}
                          </Avatar>
                          <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 0 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Typography 
                                variant="h6" 
                                sx={{ 
                                  fontWeight: 600,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                {team.name || 'Unnamed Team'}
                              </Typography>
                              {team.isEnterprise && (
                                <Chip
                                  label="Enterprise"
                                  size="small"
                                  color="secondary"
                                  sx={{ height: 20 }}
                                />
                              )}
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                              {team.members?.length || 0} members
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            sx={{ borderRadius: 20 }}
                            onClick={() => {
                              setSelectedTeam(team);
                              setTeamMembersModalOpen(true);
                            }}
                            startIcon={<Group />}
                          >
                            Members
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            endIcon={<OpenInNew />}
                            fullWidth
                            sx={{ borderRadius: 20 }}
                            onClick={() => navigate(`/team/${team.id}`)}
                          >
                            View
                          </Button>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                ) : null
              ))}
            </Grid>
          )}
        </Box>

        <Footer />

        <CreateEnterpriseTeamModal
          open={createTeamOpen}
          onClose={() => setCreateTeamOpen(false)}
          onSuccess={handleTeamCreated}
          enterpriseMembers={enterpriseMembers}
        />

        <ManageEnterpriseMembersModal
          open={manageMembersOpen}
          onClose={() => setManageMembersOpen(false)}
          enterpriseMembers={enterpriseMembers}
          onMemberAdd={handleAddMember}
          onMemberRemove={handleRemoveMember}
        />

        <EnterpriseSettingsModal
          open={enterpriseSettingsOpen}
          onClose={() => setEnterpriseSettingsOpen(false)}
          enterpriseTeam={enterpriseTeam}
          onSave={handleEnterpriseSettingsSave}
        />

        {selectedTeam && (
          <TeamMembersModal
            open={teamMembersModalOpen}
            onClose={() => {
              setTeamMembersModalOpen(false);
              setSelectedTeam(null);
            }}
            team={selectedTeam}
            allTeams={teamSpaces}
            isAdmin={isAdmin}
            enterpriseMembers={enterpriseMembers}
            setEnterpriseMembers={setEnterpriseMembers}
            setTeamSpaces={setTeamSpaces}
          />
        )}
      </div>
    </ThemeProvider>
  );
} 