import React, { useState } from "react";
import {
    Autocomplete,
  Box,
  ButtonBase,
  Chip,
  ClickAwayListener,
  FormControl,
  IconButton,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Check, Close } from "@mui/icons-material";
import HelperFunctions from "./HelperFunctions";

const ContentTagPicker = (props) => {
  const Functions = HelperFunctions();
  const { deleteable, cellView, post, refreshData, softRefresh, teamData, disablePopper = false } =
    props;
  const [newGroupName, setNewGroupName] = useState("");
  const [cellViewOpen, setCellViewOpen] = useState(false);

  const cellRef = React.useRef(null);

  let tagOptions =
    teamData &&
    teamData.posts &&
    teamData.posts
      .map((post) => post.data.tags ? JSON.parse(post.data.tags) : null)
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index);

  const [selectedTags, setSelectedTags] = useState(
    props.post.data.tags ? JSON.parse(props.post.data.tags) : []
  );

  const handleSelectedTagChange = async (event, newValue) => {
    console.log("newValue", newValue);
    const tag = newValue.props.value;
    //if group is not in selectedGroups, add it, else remove it

    let tags = selectedTags.push(tag);

    setSelectedTags(tags);

    console.log("selectedTags", selectedTags);
  };
    
  const updatePostTags = React.useCallback(async (tags) => {
      let updatedPost = await Functions.updatePostTags(post.id, teamData.id, tags);
    softRefresh();
  }, [post, teamData]);

    
  const chipStyles = {
    backgroundColor: "#f0f2f5",
    color: "#4a5056",
    fontWeight: 500,
    fontSize: "0.813rem",
    height: 32,
    borderRadius: "16px",
    '&:hover': {
        backgroundColor: "#e4e6e9"
    }
  };

  const autocompleteComponent = (
    <Autocomplete
      freeSolo
      multiple
      clearOnEscape={false}
      clearIcon={null}
      fullWidth
      value={selectedTags}
      onChange={(event, newValue) => {
        setSelectedTags(newValue);
        updatePostTags(newValue);
      }}
      options={tagOptions.sort((a, b) => (b && a) ? -b.localeCompare(a) : 0)}
      getOptionLabel={(option) => option}
      renderTags={(value, getTagProps) => (
        <Box sx={{ marginBottom: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {value.map((option, index) => (
            <Chip
              sx={chipStyles}
              key={option}
              label={option}
              deleteIcon={deleteable ? 
                <Close sx={{ fontSize: 16, color: '#6b7280' }} /> : null}
              onDelete={
                deleteable
                  ? () => setSelectedTags(
                      selectedTags.filter((tag) => tag !== option)
                    )
                  : null
              }
              {...getTagProps({ index })}
            />
          ))}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="standard"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            sx: {
              padding: '4px 8px',
              '&::placeholder': {
                color: '#9ca3af',
                fontWeight: 500
              }
            }
          }}
          sx={{
            border: "0px",
            background: "transparent",
            '& .MuiInputBase-root': {
              fontSize: '0.875rem',
              color: '#4b5563',
            }
          }}
          placeholder="Select tags or type in a new one"
          onKeyDown={(event) => {
            if (event.key === 'Backspace' && event.target.value.trim() === '') {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Chip
            sx={{
              ...chipStyles,
              backgroundColor: selected ? "#e4e6e9" : "white",
              border: 'none'
            }}
            label={option}
            icon={selected ? <Check sx={{ fontSize: 16, color: '#4a5056' }} /> : null}
          />
        </li>
      )}
    />
  );

  return (
    <Box sx={{ 
      display: "flex", 
      flexDirection: "row", 
      gap: 1, 
      width: "100%",
      minHeight: 40
    }} ref={cellRef}>
      {cellView && !cellViewOpen ? (
        <ButtonBase 
          onClick={() => !disablePopper && setCellViewOpen(true)}
          sx={{ 
            width: '100%',
            justifyContent: 'flex-start',
            padding: '4px 8px',
            borderRadius: 1,
            cursor: disablePopper ? 'default' : 'pointer',
            '&:hover': {
              backgroundColor: disablePopper ? 'transparent' : '#f9fafb'
            }
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: '100%'
          }}>
            {selectedTags && selectedTags.length > 0 ? (
              <>
                <Chip
                  sx={chipStyles}
                  label={selectedTags[0]}
                />
                {selectedTags.length > 1 && (
                  <Typography sx={{ 
                    fontSize: '0.875rem', 
                    fontWeight: 500,
                    color: '#6b7280'
                  }}>
                    {`+ ${selectedTags.length - 1}`}
                  </Typography>
                )}
              </>
            ) : (
              <Typography sx={{ 
                fontSize: '0.875rem', 
                fontWeight: 500, 
                color: '#9ca3af'
              }}>
                No Tags
              </Typography>
            )}
          </Box>
        </ButtonBase>
      ) : (
        <Box sx={{ display: "flex", flexDirection:"row", gap: 1, width:"100%" }}>
          {cellView ? (
            !disablePopper && <CustomPopper  
              cellRef={cellRef} 
              setCellViewOpen={setCellViewOpen} 
              cellViewOpen={cellViewOpen}
            >
              {autocompleteComponent}
            </CustomPopper>
          ) : autocompleteComponent}
        </Box>
      )}
    </Box>
  );
};

const CustomPopper = (props) => {
  return (
    <ClickAwayListener onClickAway={() => props.setCellViewOpen(false)}>
      <Popper
        {...props}
        open={props.cellViewOpen}
        anchorEl={props.cellRef?.current || document.body}
        placement="bottom"
        style={{
          zIndex: 1000,
          minWidth: 450
        }}
      >
        <Box sx={{ 
          p: 1.5,
          background: 'white',
          borderRadius: '8px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          maxWidth: 450
        }}>
          {props.children}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default ContentTagPicker;
