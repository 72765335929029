import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Chip,
  CircularProgress,
  ButtonGroup,
  AvatarGroup,
  LinearProgress,
} from "@mui/material";
import ThumbnailGallery from "./ThumbnailGallery";
import { formatFullDate, formatNumber, formatData, estimateViews } from "./Helpers";
import MainSummary from "./MainSummary";
import SelectChips from "./SelectChips";
import {
  CalendarTodayOutlined,
  CheckCircleOutlineRounded,
  ContentCopyOutlined,
  Download,
  FavoriteBorderOutlined,
  OpenInNew,
  VisibilityOutlined,
  CommentOutlined,
  SendOutlined,
  ExpandMore,
  Minimize,
  FullscreenExitOutlined,
  FullscreenOutlined,
  SaveAsOutlined,
  Label,
  LabelOutlined,
  LocalActivityOutlined,
  ThumbUpAltOutlined,
  StarOutline,
  InfoOutlined,
  BookmarkOutlined,
  BookmarkBorderOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { Avatar } from "@mui/material";
import { styled as muiStyled } from "@mui/system";
import html2canvas from "html2canvas";
import ContentTagPicker from "./ContentTagPicker";
import GroupPicker from "./GroupPicker";
import { min } from "lodash";
import { ArrowDownOnSquareIcon, BookmarkIcon, CalendarDaysIcon, ChatBubbleLeftRightIcon, DocumentDuplicateIcon, EyeIcon, HeartIcon, InformationCircleIcon, ShareIcon } from "@heroicons/react/24/outline";

const CircularAvatar = muiStyled(Avatar)(({ theme, minimize }) => ({
  height: minimize ? 30 : 40,
  width: minimize ? 30 : 40,
  marginRight: 10,
  background: theme.palette.primary.main,
  '& img': {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  }
}));

const parseMarkdown = (text) => {
  if (!text) return '';
  
  return text
    // Bold: **text**
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    // Italic: *text* or _text_
    .replace(/[*_](.*?)[*_]/g, '<em>$1</em>')
    // Links: [text](url)
    .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>')
    // Line breaks: \n
    .replace(/\n/g, '<br/>');
};

export default function ContentMetrics(props) {
  const { selectedPostData, teamData, refreshData, screenshot, isMobile, softRefresh } = props;
  const [engagementRate, setEngagementRate] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [renderImages, setRenderImages] = useState(true);
  const [copied, setCopied] = useState(false);
  const [views, setViews] = useState(null);
  const [likes, setLikes] = useState(null);
  const [comments, setComments] = useState(null);
  const [shares, setShares] = useState(null);
  const [saves, setSaves] = useState(null);
  const [numAccounts, setNumAccounts] = useState(null);
  const metricsScreenshotRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [hover, setHover] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [showThumbnails, setShowThumbnails] = useState(true);

  const {minimizeMetrics, setMinimizeMetrics, small, viewportHeight} = props;

  const styles = {
    '& a': {
      color: '#4728c4',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& strong': {
      fontWeight: 700,
      color: '#333'
    },
    '& em': {
      fontStyle: 'italic',
      color: '#666'
    }
  };

  // const images = React.useMemo(() => {
  //   return selectedPostData && selectedPostData.length > 0
  //     ? selectedPostData.slice(0, selectedPostData.length > 20 ? 20 : selectedPostData.length)
  //     .map((post) => post.data.thumbnail)
  //     : null;
  // }, [selectedPostData]);

  React.useEffect(() => {
    document.getElementById("metrics-panel") &&
      document
        .getElementById("metrics-panel")
        .addEventListener("mouseover", () => {
          setHovered(true);
        });

    document.getElementById("metrics-panel") &&
      document
        .getElementById("metrics-panel")
        .addEventListener("mouseout", () => {
          setHovered(false);
        });

    return () => {
      document.getElementById("metrics-panel") &&
        document
          .getElementById("metrics-panel")
          .removeEventListener("mouseover", () => {
            setHovered(true);
          });

      document.getElementById("metrics-panel") &&
        document
          .getElementById("metrics-panel")
          .removeEventListener("mouseout", () => {
            setHovered(false);
          });
    };
  }, []);

  React.useEffect(() => {
    if (selectedPostData && selectedPostData.length > 0) {
      let posts = selectedPostData.filter(
        (post) =>
          post.status == "complete"
      );

      const views = formatNumber(
        posts
          .map((post) => post.data.engagement.views > 0 ? post.data.engagement.views : 
          estimateViews(post.data.engagement.likes, post.data.engagement.comments, post.data.engagement.shares, post.data.engagement.saves, post.data.platform))
          .reduce((a, b) => a + b, 0)
      );
      const likes = formatNumber(
        posts.filter((post) => post.data.engagement.likes > 0)
          .map((post) => post.data.engagement.likes)
          .reduce((a, b) => a + b, 0)
      );
      const comments = formatNumber(
        posts
          .map((post) => post.data.engagement.comments)
          .reduce((a, b) => a + b, 0)
      );
      const shares = formatNumber(
        posts
          .map((post) => post.data.engagement.shares)
          .reduce((a, b) => a + b, 0)
      );

      const saves = formatNumber(
        posts.filter((post) => post.data.engagement.saves && post.data.engagement.saves > 0)
          .map((post) => post.data.engagement.saves)
          .reduce((a, b) => a + b, 0)
      );
      setViews(views);
      setLikes(likes);
      setComments(comments);
      setShares(shares);
      setSaves(saves);

      const engagementRate =
        posts.filter((post) => post.data.engagement.views > 0 || post.data.platform == "linkedin")
          .map(
            (post) =>
              (((post.data.engagement.likes ? post.data.engagement.likes : 0) +
                (post.data.engagement.comments ? post.data.engagement.comments : 0) +
                (post.data.engagement.shares ? post.data.engagement.shares : 0) + (post.data.engagement.saves ? post.data.engagement.saves : 0)) *
                100) /
              (post.data.engagement.views > 0 ? post.data.engagement.views : estimateViews(post.data.engagement.likes, post.data.engagement.comments, post.data.engagement.shares, post.data.engagement.saves, post.data.platform))
          )
          .reduce((a, b) => a + b, 0) / posts.length;

      setEngagementRate(engagementRate.toFixed(1));
    }

    const accounts = selectedPostData
    .map((post) => `${post.data.username.replace("@", "")}|${post.data.platform}`) // Combine username and platform
    .filter((value, index, self) => self.indexOf(value) === index); // Ensure uniqueness

    setAccounts(accounts);

    setNumAccounts(accounts.length);

  }, [selectedPostData]);

  const handleExportMetrics = async () => {
    setExporting(true);
    let style;
    
    try {
      const getProxyUrl = (url) => {
        return `https://images.weserv.nl/?url=${encodeURIComponent(url)}&default=blank`;
      };

      await document.fonts.ready;
      await new Promise(resolve => setTimeout(resolve, 100));

      if (!metricsScreenshotRef.current) return;

      style = document.createElement('style');
      style.innerHTML = `
        #metrics-screenshot-container * {
          transform-style: preserve-3d !important;
        }
        #metrics-screenshot-container img {
          visibility: visible !important;
          opacity: 1 !important;
          display: inline-block !important;
          max-width: none !important;
        }
        #metrics-screenshot-container .MuiBox-root {
          transform: translateZ(0) !important;
          backface-visibility: hidden !important;
        }
        #metrics-screenshot-container .thumbnail-stack {
          position: relative !important;
          z-index: 1 !important;
        }
        #metrics-screenshot-container .thumbnail-stack::before,
        #metrics-screenshot-container .thumbnail-stack::after {
          content: "" !important;
          position: absolute !important;
          box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;
          z-index: -1 !important;
        }
        #metrics-screenshot-container .no-screenshot {
          display: none !important;
        }
      `;
      document.head.appendChild(style);

      // Pre-process all images
      const images = metricsScreenshotRef.current.querySelectorAll('img');
      const imagePromises = Array.from(images).map(img => {
        return new Promise((resolve) => {
          const originalSrc = img.src;
          const proxyUrl = getProxyUrl(originalSrc);
          
          const tempImg = new Image();
          tempImg.crossOrigin = 'anonymous';
          tempImg.onload = () => {
            img.src = proxyUrl;
            img.crossOrigin = 'anonymous';
            img.style.cssText = `
              visibility: visible !important;
              opacity: 1 !important;
              display: inline-block !important;
              max-width: none !important;
            `;
            resolve();
          };
          tempImg.onerror = () => {
            console.warn('Failed to load image:', originalSrc);
            resolve();
          };
          tempImg.src = proxyUrl;
        });
      });

      // Wait for all images to process
      await Promise.all(imagePromises);
      
      // Add delay to ensure images are rendered
      await new Promise(resolve => setTimeout(resolve, 1000));

      const options = {
        useCORS: true,
        allowTaint: false,
        backgroundColor: '#ffffff',
        scale: 2,
        logging: true,
        imageTimeout: 30000,
        onclone: (clonedDoc) => {
          const clonedImages = clonedDoc.querySelectorAll('img');
          clonedImages.forEach(img => {
            img.style.cssText = `
              visibility: visible !important;
              opacity: 1 !important;
              display: inline-block !important;
              max-width: none !important;
            `;
          });
        },
        ignoreElements: (element) => {
          // Ignore elements that shouldn't be in the screenshot
          return element.classList.contains('no-screenshot');
        }
      };

      const canvas = await html2canvas(metricsScreenshotRef.current, options);
      
      // Convert to blob for better memory handling
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `siftsy_metrics.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 'image/png');

    } catch (err) {
      console.error('Screenshot failed:', err);
    } finally {
      if (style) {
        style.remove();
      }
      setExporting(false);
    }
  };

  const preRenderText = () => {
    if (!metricsScreenshotRef.current) return;
    
    // Force text measurement
    const textElements = metricsScreenshotRef.current.querySelectorAll('p, span, div');
    textElements.forEach(el => {
      el.style.display = 'block';
      // Force layout calculation
      el.getBoundingClientRect();
    });
  };
  
  const handleCopyMetrics = async () => {
    let metrics = "";

    if (selectedPostData && selectedPostData.length > 0) {
      
      const sortedPosts = selectedPostData
    .filter((post) => post.data.postedAt)
    .sort((a, b) => new Date(a.data.postedAt) - new Date(b.data.postedAt));
  const startPost = sortedPosts[0];
  const endPost = sortedPosts[sortedPosts.length - 1];

    let startDate = formatFullDate(startPost.data.postedAt, true);
    let endDate = formatFullDate(endPost.data.postedAt, true);

      metrics = `Posts: ${selectedPostData.length}\nAccounts: ${numAccounts}\nStart: ${startDate}\nEnd: ${endDate}\nEngagement Rate: ${engagementRate}%\nViews: ${views}\nLikes: ${likes}\nComments: ${comments}\nShares: ${shares}\nSaves: ${saves}`;
    }

    

    navigator.clipboard.writeText(metrics);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  

  return (
    <>
    <Box
      id="metrics-screenshot-container"
      sx={{
        backgroundColor: "#fff",
        border: "1px solid #DDD",
        borderRadius: 3,
        p: !minimizeMetrics ? 3 : .5,
        maxWidth: !minimizeMetrics ? 400 : "100%",
        margin: "auto",
        "&::-webkit-scrollbar": {
          width: "20px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
          borderRadius: 20,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#ddd",
          border: "5px solid #f7f8fa",
          borderRadius: 20,
        },
      }}
      ref={metricsScreenshotRef}
    >
      <Box sx={{ 
        display: "flex", 
        flexDirection: "row", 
        gap: 1, 
        alignItems: "center", 
        justifyContent: "flex-start",
        width: "100%",
        mb: 2
      }}>
        {!minimizeMetrics && selectedPostData.length > 1 && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1" fontWeight={700} color="#666">
              Posts
            </Typography>
            <Chip 
              label={`${selectedPostData.length}`} 
              sx={{
                background: "#4728c4", 
                color: "white", 
                fontWeight: 500,
                height: 24,
              
                '& .MuiChip-label': {
                  padding: 1,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '0.875rem', // 14px
                  lineHeight: 1,
                }
              }} 
              size="small"
            />
          </Box>
        )}
        
        {!exporting && (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Box 
              className="no-screenshot"
              sx={{
                gap: 0,
                mb: minimizeMetrics ? -4 : 0,
                display: 'flex',
                alignItems: 'center'
              }}
              id="metrics-actions"
            > 
              <Tooltip title="Copy Metrics" placement="top">
                <IconButton size="small" color="primary" onClick={handleCopyMetrics}>
                  {copied ? (
                    <CheckCircleOutlineRounded color="success" sx={{ width: 16, height: 16 }} />
                  ) : (
                    <DocumentDuplicateIcon style={{ height: 20, width: 20, color:"#5C6078" }} />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title="Download As Image" placement="top">
                <IconButton color="primary" size="small" onClick={handleExportMetrics}>
                  {exporting ? (
                    <CircularProgress size={16} />
                  ) : (
                    <ArrowDownOnSquareIcon style={{ height: 20, width: 20, color:"#5C6078" }} />
                  )}
                </IconButton>
              </Tooltip>
              {!minimizeMetrics && (
              <Tooltip title={showThumbnails ? "Hide Thumbnails" : "Show Thumbnails"} placement="top">
                <IconButton size="small" color="primary" onClick={() => setShowThumbnails(!showThumbnails)}>
                  {showThumbnails ? (
                    <VisibilityOutlined style={{ height: 20, width: 20, color:"#5C6078" }} />
                  ) : (
                    <VisibilityOffOutlined style={{ height: 20, width: 20, color:"#5C6078" }} />
                  )}
                </IconButton>
              </Tooltip>
              )}
            </Box>
          </>
        )}
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={minimizeMetrics ? 5 : 12}>
          <Box sx={{ display: "flex", flexDirection: "row", gap:2, alignItems:"center", justifyContent:"flex-start" }}>
            {selectedPostData &&
            selectedPostData.length > 0 &&
            selectedPostData[0].id ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: minimizeMetrics ? "row" : "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1em",
                  width: "100%",
                }} 
              >
                {selectedPostData.length == 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                      // alignItems: "center",
                      // background:"#ff0000"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "top",
                        width: "100%",
                        mb: 1,
                        justifyContent: "center",
                        alignContent: "center",
                        minWidth:200
                      }}
                    >
                      {selectedPostData[0].data.userAvatar ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            // mt: 2,
                          }}
                        >
                          <CircularAvatar
                            minimize={minimizeMetrics}
                            src={selectedPostData[0].data.userAvatar}
                          />
                        </Box>
                      ) : null}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {minimizeMetrics ? (
                          <>
                                    <Box sx={{ display: "flex", flexDirection: "row", gap:1, p:1, alignItems:"center"}}>
                                    <Typography
                                        variant={minimizeMetrics ? "body2" : "body1"}
                                        align="left"
                                        fontWeight={700}
                                      >
                                        @
                                        {selectedPostData[0].data.username.replace(
                                          "@",
                                          ""
                                        )}
                                      </Typography>
                                    <Chip label={formatData(selectedPostData[0].data.platform)} size="small" sx={{fontSize:10, fontWeight:600, backgroundColor:"#eeeaff"}}/>
                                  </Box>
                              
                                  </>
                        )
                        : (
                          <Box sx={{ display: "flex", flexDirection: "column"}}>
                        <Box sx={{ display: "flex", flexDirection: "row", gap:1}}>
                          <Typography variant={minimizeMetrics ? "body2" : "body1"} fontWeight={700} sx={{maxWidth:180, whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}
                           align="left">
                            {selectedPostData[0].data.creatorName} 
                          </Typography>
                          <Chip label={formatData(selectedPostData[0].data.platform)} size="small" sx={{fontSize:10, fontWeight:600, backgroundColor:"#eeeaff"}}/>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "top",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography
                              variant={minimizeMetrics ? "body2" : "body1"}
                              align="left"
                            >
                              @
                              {selectedPostData[0].data.username.replace(
                                "@",
                                ""
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        </Box>
                        )}
                      
                      </Box>
                   

                    </Box>
                  </Box>
                )}




    {viewportHeight > 750 && !minimizeMetrics && showThumbnails && (
                <ThumbnailGallery
                  multiple={selectedPostData.length > 1}
                  selectedPostData={selectedPostData}
                  numAccounts={numAccounts}
                  numPosts={selectedPostData.length}
                  small={small}
                />
    )}

                {!minimizeMetrics && selectedPostData.length == 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                      width:"100%"
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems:"center", justifyContent:"center" }}>
<Typography
              variant="body1"
              align="left"
              fontWeight={700}
            
            >
              About This Post
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
                      {!exporting && (
            <Button
                      variant="outlined"
                      sx={{
                        borderRadius: 12,
                        position: "relative",
                        //   width: "fit-content",
                        margin: "auto",
                        padding: "2px 12px",
                        fontSize: 12,
                      }}
                      size="smalls"
                      endIcon={<OpenInNew sx={{ height: 16, width: 16 }} />}
                      color="primary"
                      onClick={() =>
                        window.open(selectedPostData[0].link, "_blank")
                      }
                    >
                      Link
                    </Button>
                      )}

                    </Box>

            <Typography 
              variant="body2" 
              align="left" 
              fontSize={small?12:14} 
              fontWeight={400} 
              color="#333"
              sx={styles}
              dangerouslySetInnerHTML={{ 
                __html: parseMarkdown(
                  selectedPostData[0].data.summary || 
                  selectedPostData[0].data.description
                )
              }}
            />

            
            <StartEndDates selectedPostData={selectedPostData} minimizeMetrics={small || minimizeMetrics}/>


                  </Box>

                ) : <>
                
             
                  {renderImages ? 
                     <Box
                     sx={{
                       display: "flex",
                       flexDirection: "row",
                       alignItems: "center",
                       justifyContent: "center",
                     }}
                   >
    {numAccounts > 1 && ( 
                  <Grid container spacing={minimizeMetrics ? 1 : 2} sx={{display:"flex", flexDirection:"row", alignItems:"flex-end"}}>
                 
                    <Grid item xs={minimizeMetrics ? 12 : 6} sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                  <AvatarGroup
                  sx={{transform: "scale(.7)"}}
                    max={4}
                    spacing={"small"}
                    total={numAccounts}
                    variant="circular"
                    renderSurplus={(surplus) => (
                      <Avatar
                        sx={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#eeeaff",
                          color: "#333",
                          fontWeight: 700,
                        }}
                        >
                          <Typography variant="body1" fontSize={14} fontWeight={700} color="primary">
                            +{surplus}
                          </Typography>
                        </Avatar>
                      )}
                  >
                    {selectedPostData.map((post) => (
                      <CircularAvatar
                        minimize={minimizeMetrics}
                        src={post.data.userAvatar}
                      />
                    ))}
                  </AvatarGroup>
                  
                  </Grid>
                 
                 {!minimizeMetrics && (
                  <Grid item xs={minimizeMetrics ? 12 : 6} sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                  <Typography
                    variant="body1"
                    fontSize={16}
                    fontWeight={700}
                    color="#333"
                    whiteSpace={"nowrap"}
                    gutterBottom
                  >
                    {`${numAccounts} Account${numAccounts > 1 ? "s" : ""}`}
                  </Typography>
                  </Grid>
                  )}
                  </Grid>
                )}
                {minimizeMetrics && numAccounts > 1 && (
                  <>
                   <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={minimizeMetrics ? 10 : 12}
                          fontWeight={700}
                          align="left"
                        >
                          Posts
                        </Typography>
                
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 18}
                        >
                               {selectedPostData.length} 
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={minimizeMetrics ? 10 : 12}
                          fontWeight={700}
                          align="left"
                        >
                          Accounts
                        </Typography>
                
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 18}
                        >
                              {numAccounts} 
                        </Typography>
                      </Box>
                    </Box>
                    </>
                  )}

                  </Box>
                  :


                  <Grid container spacing={2}>
                  {numAccounts > 1 && (
                    <>
                    <Grid item xs={6}>
                                        <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "flex-start",
                                          flexGrow: 1,
                                          border: minimizeMetrics ? null : "1px solid #ddd",
                                          p: minimizeMetrics ? 1 : 2,
                                          borderRadius: 4,
                                          overflow:"hidden"
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            width: "100%",
                                            gap: .5,
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            fontSize={minimizeMetrics ? 10 : 12}
                                            fontWeight={700}
                                            align="left"
                                          >
                                            Posts
                                          </Typography>
                                  
                                        </Box>
                  
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            width: "100%",
                                            gap: 1,
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            fontWeight={700}
                                            fontSize={minimizeMetrics ? 16 : 18}
                                          >
                                                       {selectedPostData.length} 
                                          </Typography>
                                        </Box>
                                      </Box>  
                                      </Grid>
                                      <Grid item xs={6}>
                  
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                        overflow:"hidden"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={minimizeMetrics ? 10 : 12}
                          fontWeight={700}
                          align="left"
                        >
                          Accounts
                        </Typography>
                
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 18}
                        >
                                     {numAccounts} 
                        </Typography>
                      </Box>
                    </Box>  
                    </Grid>
                    </>                
               
                  )}
                  </Grid>
   
                  }
         
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    overflow:"hidden"
                  }}
                >
                  <StartEndDates selectedPostData={selectedPostData} minimizeMetrics={small || minimizeMetrics}/>
                </Box>
    
                </>}
              </Box>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={minimizeMetrics ? 2 : small ? 12: 12}>
          {engagementRate && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                flexGrow: 1,
                cursor: "pointer",
                gap: small || minimizeMetrics ? 0 : 1,
                border: small || minimizeMetrics ? null : "1px solid #ddd",
                p: small || minimizeMetrics ? 1 : 2,
                borderRadius: 4,
              }}
            >
       
                <>
                  {/* <LocalActivityRounded sx={{ height: 16 }} /> */}
                  <Typography
                    variant="body1"
                    fontSize={small || minimizeMetrics ? 10 : 14}
                    fontWeight={700}
                    align="left"
                  >
                    <Tooltip
        title={<Typography variant="body1" fontSize={16} fontWeight={400} color="#333">
          <b>
          The engagement rate is the percentage of people who saw an individual post and engaged with it.
          </b>
          <br/><br/>
        It is calculated by dividing all forms of engagement - likes, comments, saves, reposts, and shares - by the total number of views for each individual post.
        <br/><br/>
        The engagement rate for multiple posts is the average engagement rate for all of the individual posts.<br/><br/>
        <i>
        An account's follower or subscriber count does NOT factor into this engagement rate.
        </i>
        </Typography>}

componentsProps={{
  tooltip: {
    sx: {
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
      backgroundColor: '#fff', // Optional: background color for better contrast
      color: '#000', // Optional: text color
    },
  }
}}
        placement="bottom"
      >
        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:.5}}>
          
        <Typography
          variant="body1"
          fontSize={small || minimizeMetrics ? 10 : 14}
          fontWeight={700}
          align="left"
         
        >
          Engagement Rate
        
        </Typography>
        {!minimizeMetrics && (
        <IconButton size="small">
            <InformationCircleIcon  style={{height: 16, paddingBottom: 2}}/>
          </IconButton>
        )}
          </Box>
      </Tooltip>


                    
                  </Typography>
           
                  {!isNaN(engagementRate) && isFinite(engagementRate) && views != 0 ? (
                    <Typography
                      variant="body1"
                      fontSize={small || minimizeMetrics ? 16: 20}
                      fontWeight={700}
                      align="left"
                    >
                      {engagementRate}%{selectedPostData.every((post) => post.data.platform == "linkedin" ? "*" : "")}
                      {/* {selectedPostData.some((post) => post.data.platform == "linkedin" || (post.data.platform == "instagram" && post.data.postType == "feed")) ? "*" : ""} */}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      color={"#999"}
                      fontSize={small || minimizeMetrics ? 16: 20}
                      fontWeight={700}
                    >
                      N/A
                    </Typography>
                  )}
                </>
            </Box>
          )}
        </Grid>

        <Grid item xs={minimizeMetrics ? 4 : 12}>
          {selectedPostData &&
          selectedPostData.length > 0 &&
          selectedPostData[0].id ? (
            <>
              <Box>
                <Grid container spacing={1}>
                <Grid item xs={small || minimizeMetrics ? 2: 6}>
                <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: small || minimizeMetrics ? null : "1px solid #ddd",
                        p: small || minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <EyeIcon
                         style={{ height: 16, width:16 }} />
 {!small && !minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                       
                        >
                          Views
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={small || minimizeMetrics ? 16 : 18}
                        >
                          {views}

                          {selectedPostData.some(
                            (post) => post.data.engagement.views == 0
                          )
                            ? "*"
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={small || minimizeMetrics ? 2: 6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: small || minimizeMetrics ? null : "1px solid #ddd",
                        p: small || minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <ChatBubbleLeftRightIcon style={{ height: 16, width:16, color: "#333" }} />
                        {!small && !minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                         
                        >
                          Comments
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={small || minimizeMetrics ? 16 : 18}
                        >
                          {comments}
                     
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={small || minimizeMetrics ? 2: 4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: small || minimizeMetrics ? null : "1px solid #ddd",
                        p: small || minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <HeartIcon
                           style={{ height: 16, width:16 }} />
 {!small && !minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          
                        >
                          Likes
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={small || minimizeMetrics ? 16 : 18}
                        >
                          {likes}

                          {selectedPostData.length == 1 &&
                          selectedPostData[0].data.engagement.likes == 0
                            ? "**"
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={small || minimizeMetrics ? 2: 4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: small || minimizeMetrics ? null : "1px solid #ddd",
                        p: small || minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <ShareIcon  style={{ height: 16, width:16 }} />
                        {!small && !minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                        
                        >
                          Shares
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={small || minimizeMetrics ? 16 : 18}
                        >
                          {shares}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                 
                  <Grid item xs={small || minimizeMetrics ? 2: 4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: small || minimizeMetrics ? null : "1px solid #ddd",
                        p: small || minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <BookmarkIcon  style={{ height: 16, width:16 }} />
                        {!small && !minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                         
                        >
                          Saves
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={small || minimizeMetrics ? 16 : 18}
                        >
                          { saves }
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                
                </Grid>
              </Box>
              {!minimizeMetrics && selectedPostData.some(
                (post) => post.data.platform == "instagram"
              ) ? (
                     <Box sx={{ 
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'center'
                }}>
                              <Typography 
                      variant="caption" 
                      sx={{ 
                        color: '#666',
                        fontSize: '0.75rem',
                        textAlign: 'center',
                        width: '100%'
                      }}
                    >
                    {/* <Tooltip title="Top-line view/impression count is currently not available from LinkedIn and is estimated based on engagement and historical averages." placement="top"> */}
                    *Shares and Saves may not be available for Instagram posts.
                    {/* </Tooltip> */}
                  </Typography>
                </Box>
              ) : null}
              {!minimizeMetrics && selectedPostData.some(
                (post) => post.data.engagement.views == 0 && post.data.platform == "linkedin"
              ) ? (
                <Box sx={{ 
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'center'
                }}>
                  {selectedPostData.some(
                    (post) => post.data.engagement.views == 0
                  ) && (
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: '#666',
                        fontSize: '0.75rem',
                        textAlign: 'center',
                        width: '100%'
                      }}
                    >
                      *View/Impressions estimated for LinkedIn.
                    </Typography>
                  )}
                </Box>
                
              ) : null}
              {!minimizeMetrics && selectedPostData.some(
                (post) => post.data.engagement.views == 0 && post.data.platform == "instagram" && post.data.postType == "feed"
              ) ? (
              <Tooltip title="Top-line view/impression count is currently not available for static, in-feed Instagram posts." placement="top">
              {/* and is estimated based on engagement and historical averages." placement="top"> */}
              <Box sx={{ 
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'center'
                }}>
                              <Typography 
                      variant="caption" 
                      sx={{ 
                        color: '#666',
                        fontSize: '0.75rem',
                        textAlign: 'center',
                        width: '100%'
                      }}
                    >
                    *View count not available for IG static posts.
                  </Typography>

                </Box>
                </Tooltip>
              ) : null}
              {!small && !minimizeMetrics && selectedPostData.length == 1 &&
              selectedPostData[0].data.engagement.likes == 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    pt: 1,
                  }}
                >
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{ color: "#888" }}
                  >
                    **View or like count may be hidden by creator.
                  </Typography>
                </Box>
              ) : null}
            </>
          ) : null}

        </Grid>
        {!small && !minimizeMetrics && selectedPostData &&
        selectedPostData.length == 1 &&
        selectedPostData[0].id ? (
          <Box sx={{ display:"flex", flexDirection:"column", alignItems:"flex-start", p: 2, gap:2, width:"100%" }}>

            <Typography
              variant="body1"
              align="left"
              fontWeight={700}
            
            >
              Tags
            </Typography>
         
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, width:"100%" }}>
            {/* {selectedPostData[0].data.tags

              ? JSON.parse(selectedPostData[0].data.tags).map((tag) => (
                  <Chip
                    label={tag}
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        padding: "6px 12px",
                      },
                    }}
                  />
                ))
              : null} */}
              
              <ContentTagPicker teamData={teamData}
              refreshData={refreshData}
              data={{ groupIds: selectedPostData[0].groupIds }}
              post={selectedPostData[0]}
              softRefresh={softRefresh}
              />
            
              </Box>
          </Box>
        ) : null}

        {!small && !exporting && !minimizeMetrics && selectedPostData &&
        selectedPostData.length == 1 &&
        selectedPostData[0].id ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              p:2,
              width:"100%"
            }}
          >
            <Typography variant="body1" align="left" fontWeight={700}>
              Campaigns
            </Typography>

            <GroupPicker
              refreshData={refreshData}
              data={{ groupIds: selectedPostData[0].groupIds }}
              teamData={teamData}
              postIds={[selectedPostData[0].id]}
              softRefresh={softRefresh}
            />
          </Box>
        ) : null}
      </Grid>

        {selectedPostData.length == 1 &&
          selectedPostData[0].data.engagement.likes == 0 && (
          <Typography 
            variant="caption" 
            sx={{ 
              color: '#666',
              fontSize: '0.75rem',
              textAlign: 'center',
              width: '100%'
            }}
          >
            **Like count hidden by platform.
          </Typography>
        )}
      
    </Box>

</>
  );
}

function StartEndDates(props) {
  const { selectedPostData, minimizeMetrics } = props;

  // Add null checks for selectedPostData
  if (!selectedPostData || selectedPostData.length === 0) {
    return null;
  }

  const sortedPosts = selectedPostData
    .filter((post) => post?.data?.postedAt) // Add null check for post and post.data
    .sort((a, b) => new Date(a.data.postedAt) - new Date(b.data.postedAt));

  // Check if we have any valid posts after filtering
  if (!sortedPosts.length) {
    return null;
  }

  const startPost = sortedPosts[0];
  const endPost = sortedPosts[sortedPosts.length - 1];

  // Add null checks for post dates
  const startDate = startPost?.data?.postedAt;
  const endDate = endPost?.data?.postedAt;

  if (!startDate || !endDate) {
    return null;
  }

  const sameYear = new Date(startDate).getFullYear() === new Date(endDate).getFullYear();

  if (startPost == endPost) {
    return (
      <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <CalendarTodayOutlined
                        sx={{ height: 14, width:14, color: "#333" }} />

{!minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                        >
                          Date
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                             <Typography variant="body1" fontSize={14} fontWeight={700}>
        {formatFullDate(startPost.data.postedAt, true)}
      </Typography>
                      </Box>
                    </Box>
                    

    );
  } else {
    return (
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flexGrow: 1,
        border: minimizeMetrics ? null : "1px solid #ddd",
        p: minimizeMetrics ? 1 : 2,
        gap: minimizeMetrics ? 0 : .5,
        borderRadius: 4,
     

      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          gap: 1,
        }}
      >
        <CalendarDaysIcon
        style={{ height: 14, width:14 }} />


{!minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                        >
                          Dates
                        </Typography>
                        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          gap: 1,
        }}
      >
      <Typography variant="body1" fontSize={minimizeMetrics ? 14 : 16} fontWeight={700} sx={{textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        width:"100%"}}>
        {`${formatFullDate(startPost.data.postedAt, !sameYear)}  -  ${formatFullDate(endPost.data.postedAt, true)}`}
      </Typography>

      </Box>
    </Box>


    );
  }
}
