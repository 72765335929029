import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Autocomplete,
    Toolbar,
    Checkbox,
    Switch,
    ToggleButtonGroup,
    ToggleButton,
    Fab,
  } from "@mui/material";
  import React, { useCallback, useState } from "react";
  import { emphasize } from "@mui/material/styles";
  import { styled as muiStyled } from "@mui/material/styles";
  import {
    AccountCircleOutlined,
    Add,
    AddToPhotosOutlined,
    AirplaneTicket,
    ArrowBack,
    ArrowBackIos,
    ArrowBackIosNewOutlined,
    ArrowForwardIos,
    BarChartOutlined,
    CalendarMonthOutlined,
    CalendarTodayOutlined,
    CampaignOutlined,
    Check,
    CheckCircleOutlineOutlined,
    Clear,
    ClearAll,
    Close,
    CloseOutlined,
    CloseRounded,
    CommentBankOutlined,
    CommentOutlined,
    CopyAllOutlined,
    DeleteForeverOutlined,
    DoneAll,
    Edit,
    EditAttributesOutlined,
    EditNote,
    FileCopy,
    Filter,
    FilterList,
    FilterListOffOutlined,
    Folder,
    FolderCopy,
    ForumOutlined,
    Group,
    GroupOutlined,
    Info,
    InsertCommentOutlined,
    LabelOutlined,
    LocalOfferOutlined,
    ModeCommentOutlined,
    PhotoAlbumOutlined,
    PhotoLibrary,
    PlaylistAdd,
    PlaylistAddCheck,
    PostAdd,
    PostAddOutlined,
    Public,
    Redo,
    Refresh,
    Replay,
    Reply,
    ReplySharp,
    Restore,
    Save,
    SaveOutlined,
    SelectAll,
    Settings,
    SwitchAccountOutlined,
    TagOutlined,
    TimelineOutlined,
    VideoFileOutlined,
    VideoLibrary,
    VideoLibraryOutlined,
    VideoSettingsOutlined,
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useUser, UserButton } from "@clerk/clerk-react";
  import {v4 as uuidv4} from "uuid";
  import HelperFunctions from "./HelperFunctions";
  import GroupView from "./GroupView";
  import {
    formatData,
    formatNumber,
    formatPlatform,
    UTCtoLocal,
    formatFullDate,
    icons,
  } from "./Helpers";
  import AddNewModal from "./AddNewModal";
  import Footer from "./Footer";
  import { useNavigate } from 'react-router-dom';

export default function GroupAutoComplete(props){
    const {
      selectedGroups,
      setSelectedGroups,
      disableGroupChange,
      teamData,
      softRefresh,
      groupMatchType,
      setGroupMatchType,
      handleGroupChange,
      highlightGroupField,
    } = props;

    const Functions = HelperFunctions();
    const autocompleteRef = React.useRef(null);
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
    const [newCampaignName, setNewCampaignName] = React.useState("");

    const nameExists = (name) => teamData.groups.find((group) => group.name.trim().toLowerCase() == name.trim().toLowerCase()) ? true : false;

    const [campaignSelectorOpen, setCampaignSelectorOpen] = React.useState(false);

    const handleClose = (event) => {
      if (autocompleteRef.current && autocompleteRef.current.contains(event.target) || event.key === 'Enter') {
        return; // Do not close if the click is within the autocomplete
      }
      setCampaignSelectorOpen(false);
    };
    
    const [hoveredCampaign, setHoveredCampaign] = React.useState("");

    const handleCreateCampaign = async () => {
      if (newCampaignName.trim() === "" || nameExists(newCampaignName)) {
        return;
      }
      
      // Create the campaign using the Functions helper
      const campaign = await Functions.createGroup(newCampaignName, teamData.id, null, "campaign");
      
      // Close the modal
      setConfirmModalOpen(false);
      
      // Clear the input and state
      if (autocompleteRef.current?.querySelector('input')) {
        autocompleteRef.current.querySelector('input').value = '';
      }
      setNewCampaignName(''); // Clear the modal input state
      
      // Refresh the view
      softRefresh();
      
      // Optionally select the newly created campaign
      if (campaign) {
        const syntheticEvent = {
          target: { value: [...selectedGroups, campaign] },
          key: 'Enter',
          preventDefault: () => {},
          stopPropagation: () => {}
        };
        handleGroupChange(syntheticEvent, [...selectedGroups, campaign]);
      }
    };

    return (
      <ClickAwayListener onClickAway={() => setCampaignSelectorOpen(false)}>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Autocomplete
        key={`autocomplete-${selectedGroups.length}`}
            ref={autocompleteRef}
          freeSolo
          disableCloseOnSelect
          onClose={handleClose}

          onOpen={() => setCampaignSelectorOpen(true)}
          open={campaignSelectorOpen}
          
          // clearOnBlur={false}
          sx={{
            width: "100%",
            cursor: "pointer",
            borderRadius: 2,
            color: "#999",
            fontWeight: 600,
            border: highlightGroupField ? "2px solid #4727c3" : "1px solid rgba(0,0,0,0.12)",
            backgroundColor: "#fff",
            mt: 1.5,
            transition: 'all 0.2s ease',
            '&:hover': {
              border: highlightGroupField ? "2px solid #4727c3" : "1px solid rgba(0,0,0,0.23)",
              backgroundColor: '#fafafa'
            },
            "& .MuiAutocomplete-inputRoot": {
              fontSize: "15px",
              padding: "4px 8px",
            },
            "& .MuiAutocomplete-option": {
              fontSize: "15px",
              transition: 'background-color 0.2s ease',
            },
            "& .MuiAutocomplete-paper": {
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
              border: '1px solid rgba(0,0,0,0.08)'
            }
          }}
          forcePopupIcon={true}
          
          
          clearIcon={<Clear />}
          multiple
        
          noOptionsText={"No campaigns found"}
          options={
            teamData.groups.filter(
              (group) =>
                group.type &&
                group.type == "campaign"
            ).sort((a, b) => a.name.localeCompare(b.name))
            .map((campaign) =>
              (
               [campaign, ...teamData.groups.filter(
                (group) => group.campaignId == campaign.id && group.type == "group"
              )]
              
              // && selectedGroups.some((group) => group.id == campaign.id || group.campaignId == campaign.id))
              //   ? [campaign]
              //       .concat(
              //         teamData.groups.filter(
              //           (group) =>
              //             group.type == "group" &&
              //             group.campaignId == campaign.id 
                          
              //         )
              //       )
              //       .flat()
              ).flat()
            )
            .flat()
        }
          getOptionLabel={(option) => option.name}
          renderOption={(
            props,
            option,
            { selected }
          ) => {
            if (option.type === "campaign" || option.type === "group") {
              return (
              <GroupOption 
              type={option.type}
              hoveredCampaign={hoveredCampaign}
              setHoveredCampaign={setHoveredCampaign}
              softRefresh={softRefresh}
              renderProps={props}
              key={option}
              option={option}
              selected={selected}
              disableGroupChange={disableGroupChange}
              teamData={teamData}
              selectedGroups={selectedGroups}
              confirmModalOpen={confirmModalOpen}
              />
              );
              
            } else if (
              option.type === "campaignLabel"
            ) {
              return (
                <>
                  <li
                    key={option.id}
                    {...props}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        selectedGroups.length != 0
                      ) {
                        setSelectedGroups([]);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={
                          selectedGroups.length == 0
                        }
                        size="small"
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#666",
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                      >
                        All Posts
                        {/* {teamData.posts.length > 0
                          ? ` (${teamData.posts.length})`
                          : ""} */}
                      </Typography>
  
                    </Box>
                  </li>
                  <li
                    key={option.id}
                    {...props}
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom:
                          "1px solid #ddd",
                      }}
                    />
                  </li>
                </>
              );
            } else if (
              option.type === "groupLabel"
            ) {
              return (
                <li
                  key={option.id}
                  {...props}
                  style={{ pointerEvents: "none" }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#555",
                      fontWeight: 600,
                      fontSize: 14,
                      ml: 2,
                    }}
                  >
                    Groups
                  </Typography>
                </li>
              );
            } 
            
            // else if (option.type === "newGroup") {
            //   return (
            //     <li
            //       key={option.id}
            //       {...props}
            //       onClick={(e) => {
            //         e.preventDefault();
            //         e.stopPropagation();
            //         openAddNew("group", true);
            //       }}
            //     >
            //       <Box
            //         sx={{
            //           display: "flex",
            //           flexDirection: "row",
            //           gap: 1,
            //           alignItems: "center",
            //           ml: 3,
            //         }}
            //       >
            //         <Button
            //           size="small"
            //           sx={{
            //             color: "#666",
            //             textTransform: "none",
            //             fontSize: 14,
            //             fontWeight: 600,
            //           }}
            //           startIcon={<Add />}
            //           onClick={() =>
            //             openAddNew("group", true)
            //           }
            //         >
            //           Add New Group
            //         </Button>
            //       </Box>
            //     </li>
            //   );
            // } else if (
            //   option.type === "newCampaign"
            // ) 
            // {
            //   return (
            //     <li
            //       key={option.id}
            //       {...props}
            //       onClick={(e) => {
            //         e.preventDefault();
            //         e.stopPropagation();
            //         openAddNew("campaign", true);
            //       }}
            //     >
            //       <Box
            //         sx={{
            //           display: "flex",
            //           flexDirection: "row",
            //           gap: 1,
            //           alignItems: "center",
            //           ml: 1,
            //         }}
            //       >
            //         <Button
            //           size="small"
            //           sx={{
            //             color: "#666",
            //             textTransform: "none",
            //             fontSize: 14,
            //             fontWeight: 600,
            //           }}
            //           startIcon={<Add />}
            //           onClick={() =>
            //             openAddNew("campaign", true)
            //           }
            //         >
            //           Create New Campaign
            //         </Button>
            //       </Box>
            //     </li>
            //   );
            // } 
            
            else if (
              option.type === "matchTypeSelector"
            ) {
              return (
                <li
                  key={option.id}
                  {...props}
                  style={{ pointerEvents: "none" }}
                  onClick={(e) => {
                    // e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Tooltip
                    sx={{
                      color: "#999",
                      fontSize: 14,
                      backgroundColor: "#f5f5f5", // Add this line to change the background color
                      border: "1px solid #ccc", // Add this line to add a border
                    }}
                    title={
                      <>
                        {
                          "Select 'OR' to view posts in any selected campaigns."
                        }
                        <br />
                        <br />
                        {
                          "Select 'AND' to view posts in all selected campaigns."
                        }
                      </>
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        flexGrow: 1,
                        alignItems: "center",
                        ml: 3,
                        mt: 2,
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }} />
                      <FilterList
                        sx={{
                          color: "#aaa",
                          width: 20,
                          height: 20,
                        }}
                      />
                      <ToggleButtonGroup
                        value={groupMatchType}
                        sx={{
                          pointerEvents: "auto",
                          ml: 1,
                          mr: 1,
                        }}
                        exclusive
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setGroupMatchType(
                            e.target.value
                          );
                        }}
                        size="small"
                      >
                        <ToggleButton
                          value="or"
                          sx={{
                            borderRadius: 30,
                            padding: "4px 10px",
                            color: "#666",
                            "&.Mui-selected": {
                              backgroundColor:
                                "#666",
                              color: "#fff",
                            },
                          }}
                        >
                          OR
                        </ToggleButton>
                        <ToggleButton
                          value="and"
                          sx={{
                            borderRadius: 30,
                            padding: "4px 10px",
                            color: "#666",
                            "&.Mui-selected": {
                              backgroundColor:
                                "#666",
                              color: "#fff",
                            },
                          }}
                        >
                          AND
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {/* <Switch 
  sx={{pointerEvents:"auto"}}
  size="small" checked={groupMatchType == "and"} onChange={(e) => 
  {e.preventDefault(); e.stopPropagation();
  setGroupMatchType(groupMatchType == "and" ? "or" : "and")}} /> */}
                    </Box>
                  </Tooltip>
                </li>
              );
            }
          }}
          onChange={handleGroupChange}
          value={
            selectedGroups.length > 0
              ? selectedGroups
              : [
                  {
                    id: "default",
                    type: "default",
                    name: `All Posts`
                    // ${
                    //   teamData.posts.length > 0
                    //     ? `(${teamData.posts.length})`
                    //     : ""
                    // }
                    // `
                    ,
                  },
                ]
          }
          isOptionEqualToValue={(option, value) =>
            option.id == value.id
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <>
                <Chip
                  key={index}
                  sx={{
                    backgroundColor: "#f8f9fa",
                    border: "1px solid rgba(0,0,0,0.08)",
                    borderRadius: 2,
                    height: 32,
                    '&:hover': {
                      backgroundColor: "#f0f1f2",
                    },
                    '& .MuiChip-deleteIcon': {
                      color: '#666',
                      '&:hover': {
                        color: '#333'
                      }
                    }
                  }}
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#555",
                        fontWeight: 500,
                        fontSize: 14,
                      }}
                    >
                      {option.name}
                    </Typography>
                  }
                  {...getTagProps({ index })}
                  onDelete={
                    option.id == "default"
                      ? null
                      : getTagProps({ index }).onDelete
                  }
                />
                {value.length > 1 && index < value.length - 1 && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#666",
                      fontWeight: 500,
                      fontSize: 13,
                      mx: 0.5,
                      userSelect: 'none'
                    }}
                  >
                    {groupMatchType.toUpperCase()}
                  </Typography>
                )}
              </>
            ))
          }
          renderInput={(params) => (
            <Box sx={{display: "flex", alignItems: "center", width: "100%"}}>
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  sx: { 
                    overflow: "hidden",
                    '& fieldset': {
                      border: 'none'
                    }
                  },
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "15px",
                    fontWeight: autocompleteRef.current && 
                      autocompleteRef.current.querySelector('input').value && 
                      !nameExists(autocompleteRef.current.querySelector('input').value) 
                        ? 600 
                        : 400,
                    color: autocompleteRef.current && 
                      autocompleteRef.current.querySelector('input').value && 
                      !nameExists(autocompleteRef.current.querySelector('input').value)
                        ? '#4728c4'
                        : '#666',
                    '&::placeholder': {
                      color: '#999',
                      opacity: 0.8
                    }
                  }
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#666",
                        fontWeight: 500,
                        fontSize: 15,
                      }}
                    >
                      Campaign
                    </Typography>
                    <CampaignOutlined
                      sx={{
                        color: "#666",
                        height: 18,
                        width: 18,
                      }}
                    />
                  </Box>
                }
                placeholder="Type to search or create new campaign"
              />

              {/* Create Campaign Popper */}
              {autocompleteRef.current && autocompleteRef.current.querySelector('input').value && 
               !nameExists(autocompleteRef.current.querySelector('input').value) && (
                <Popper
                  open={true}
                  anchorEl={autocompleteRef.current.querySelector('input')}
                  placement="top-start"
                  sx={{ 
                    zIndex: 2000, 
                    mb: 2,
                    '& .MuiPaper-root': {
                      borderRadius: 2,
                      boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                    }
                  }}
                >
                  <Paper elevation={0} sx={{
                    p: 1.5,
                    background: '#fff',
                    border: '1px solid rgba(0,0,0,0.08)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <Add sx={{ color: '#4728c4', height: 16 }} />
                    <Typography sx={{ 
                      color: '#666',
                      fontSize: 13,
                      fontWeight: 500,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5
                    }}>
                      Press <kbd style={{
                        fontWeight: 600,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 4,
                        border: '1px solid rgba(0,0,0,0.08)',
                        padding: '2px 6px',
                        fontSize: '12px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.04)'
                      }}>Enter</kbd> to create new campaign
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setNewCampaignName(autocompleteRef.current.querySelector('input').value);
                        setConfirmModalOpen(true);
                      }}
                      sx={{
                        ml: 1,
                        textTransform: 'none',
                        backgroundColor: '#4728c4',
                        fontWeight: 500,
                        fontSize: 13,
                        minHeight: 32,
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: '#3a1f9e',
                          boxShadow: 'none'
                        }
                      }}
                    >
                      Create
                    </Button>
                  </Paper>
                </Popper>
              )}

              {/* Create Campaign Dialog */}
              <Dialog
                open={confirmModalOpen}
                onClose={() => setConfirmModalOpen(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                  sx: {
                    borderRadius: 3,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <DialogTitle sx={{ 
                  pb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  pt: 3,
                  px: 3
                }}>
                  <Typography variant="h6" sx={{
                    fontWeight: 600,
                    color: '#333',
                    fontSize: 18
                  }}>
                    Create New Campaign
                  </Typography>
                </DialogTitle>
                <DialogContent sx={{ pt: 2, px: 3 }}>
                  <DialogContentText sx={{
                    mb: 2,
                    color: '#666',
                    fontSize: '0.95rem'
                  }}>
                    Create a new campaign to organize and group your content.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    fullWidth
                    label="Campaign Name"
                    value={newCampaignName}
                    onChange={(e) => setNewCampaignName(e.target.value)}
                    variant="outlined"
                    sx={{ 
                      mt: 1,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        backgroundColor: '#fafafa',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          backgroundColor: '#fff'
                        },
                        '&.Mui-focused': {
                          backgroundColor: '#fff'
                        }
                      }
                    }}
                    error={nameExists(newCampaignName)}
                    helperText={nameExists(newCampaignName) ? 
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'error.main' }}>
                        <Info sx={{ width: 16, height: 16 }} />
                        Campaign name already exists
                      </Box> 
                      : ""}
                  />
                </DialogContent>
                <DialogActions sx={{ 
                  px: 3, 
                  pb: 3,
                  gap: 1
                }}>
                  <Button 
                    onClick={() => setConfirmModalOpen(false)}
                    sx={{ 
                      color: '#666',
                      textTransform: 'none',
                      fontWeight: 500,
                      px: 3,
                      '&:hover': {
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCreateCampaign}
                    disabled={newCampaignName.trim() === "" || nameExists(newCampaignName)}
                    sx={{
                      backgroundColor: '#4728c4',
                      '&:hover': {
                        backgroundColor: '#3a1f9e'
                      },
                      textTransform: 'none',
                      fontWeight: 500,
                      px: 3,
                      borderRadius: 2,
                      boxShadow: 'none',
                      '&:disabled': {
                        backgroundColor: '#e0e0e0',
                        color: '#999'
                      }
                    }}
                  >
                    Create Campaign
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        />
      </Box>
      </ClickAwayListener>
    );
  } 
  

const GroupOption = React.forwardRef(({ option, ...props }, ref) => {
    const {setHoveredCampaign, hoveredCampaign, type, selectedGroups, selected, disableGroupChange, teamData, handleGroupChange, renderProps, softRefresh, confirmModalOpen } = props;
  
    const [addGroup, setAddGroup] = useState(false);
    const [editName, setEditName] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");
    const [updatedGroupName, setUpdatedGroupName] = useState(option.name);
    const Functions = HelperFunctions();

    // const textFieldRef = React.useRef(null);
    const inputRef = React.useRef(null);
    const groupOptionRef = React.useRef(null);
    const [show, setShow] = React.useState(true);
    const [hovered, setHovered] = React.useState(false);
    const [newlyCreated, setNewlyCreated] = React.useState(option.createdAt && new Date(option.createdAt) > new Date(new Date().setDate(new Date().getDate() - 1)));
  
    React.useEffect(() => {
      if (groupOptionRef.current) {
        groupOptionRef.current.addEventListener("mouseenter", () => {
          setHovered(true);
          if(option.type == "campaign" && hoveredCampaign != option.id){
          setHoveredCampaign(option.id);
          }
        });
  
        groupOptionRef.current.addEventListener("mouseleave", () => {
          setHovered(false);
        });
      }
  
      return () => {
        if (groupOptionRef.current) {
          groupOptionRef.current.removeEventListener("mouseenter", () => {
            setHovered(true);
            if(option.type == "campaign" && hoveredCampaign != option.id){
            setHoveredCampaign(option.id);
          }
          });
    
          groupOptionRef.current.removeEventListener("mouseleave", () => {
            setHovered(false);
          });
        }
  
  
      };
    }, [groupOptionRef.current]);
  
  
    // React.useImperativeHandle(ref, () => ({
    //   focus: () => {
    //     inputRef.current?.focus();
    //   }
    // }));
    //check if name exists in teamData.groups
  
    const nameExists = (name) => teamData.groups.find((group) => group.name.trim().toLowerCase() == name.trim().toLowerCase()) ? true : false;
  
  const handleNewGroup = React.useCallback(async () => {
      let group = await Functions.createGroup(newGroupName, teamData.id, option.id);
      //set delay
      setNewGroupName("");
      setAddGroup(false);
      softRefresh();
    }, [newGroupName, option.id, teamData.id]);
  
    const handleEditName = React.useCallback(async () => {
      let group = await Functions.updateGroupName(updatedGroupName, option.id);
  
      setEditName(false);
      softRefresh();
      setUpdatedGroupName(option.name);
  
    }, [updatedGroupName, option.id]);
  
  
    const handleAddGroupClick = (e) => {
  
          e.preventDefault();
          e.stopPropagation(); 
          setAddGroup(true);
          
    };
  
    const handleEditClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setEditName(!editName);
      setUpdatedGroupName(option.name);
    }
  
    const focusTextField = () => {
        inputRef.current?.focus();
    };
  
    React.useEffect(() => {
      if(!editName){
        setUpdatedGroupName(option.name);
      }
    },[editName]);
    
    const processEnter = React.useCallback((event) => {
      if (props.confirmModalOpen && event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        return;
      }

      else if ((addGroup || editName) && event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
      
        if (newGroupName.trim() !== "" && !nameExists(newGroupName) && newGroupName !== option.name) {
          handleNewGroup();
        }
        if (updatedGroupName !== option.name && updatedGroupName.trim() !== "" && !nameExists(updatedGroupName)) {
          handleEditName();
        }
      }
    
    }, [addGroup, newGroupName, editName, updatedGroupName, handleNewGroup, handleEditName, option, props.confirmModalOpen]);
    
    React.useEffect(() => {
      const handleKeyDown = (event) => processEnter(event);
    
      if (addGroup || editName) {
        window.addEventListener("keydown", handleKeyDown);
      }
    
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [addGroup, editName, processEnter, props.confirmModalOpen]);
  
    const showGroup = React.useCallback(() => {
      if (type == "campaign") {
        return true;
      } else if (type == "group") {
  
        return (
          (option.campaignId == hoveredCampaign) ||
          selectedGroups.map((group) => group.id).includes(option.id) ||
          selectedGroups.map((group) => group.id).includes(option.campaignId) ||
          selectedGroups.map((group) => group.campaignId).includes(option.id) ||
          selectedGroups
            .map((group) => group.campaignId)
            .includes(option.campaignId)
        );
  
      }
    }
    ,[type, selectedGroups, option, hoveredCampaign]);
  
    React.useEffect(() => {
  
      setShow(showGroup());
  
    }, [hoveredCampaign]);
        
    return (
     
      <>
        <li
          {...renderProps}
          style={{
            pointerEvents: disableGroupChange ? "none" : "auto",
            display: show ? "block" : "none",
          }}
          ref={groupOptionRef}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              flexGrow: 1,
              ml: type == "group" ? 4 : 0,
            }}
          >
            <Checkbox checked={selected} size="small" />
  
            {!editName ? (
                <>
              <Typography
                variant="body1"
                sx={{
                  color: "#666",
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {option.name}
  
                {option.type == "group" && (` (${
                  teamData.posts.filter(
                    (post) => post.groupIds && post.groupIds.includes(option.id)
                  ).length
                })`)}


              </Typography>


                {newlyCreated &&
                    <Chip label="New" size="small" sx={{background:"#edecfe", color:"#666"}}/>
                }

              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  p: 1,
                  backgroundColor: "#fff",
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  flexGrow: 1,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  focusTextField();
                }}
              >
                <TextField
                  fullWidth
                  variant="standard"
                  size="small"
                  placeholder="New Group"
                  value={updatedGroupName}
                  onChange={(e) => setUpdatedGroupName(e.target.value)}
                  inputProps={{
                    style: { fontSize: 14, fontWeight: 600, color: "#666" },
                    ref: inputRef,
                  }}
                  InputProps={{ disableUnderline: true }}
                  maxLength={60}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Chip
                  icon={<SaveOutlined sx={{ color: "#999" }} />}
                  label="Save"
                  id="edit-group-name-save-button"
                  size="small"
                  disabled={
                    updatedGroupName.trim() == "" ||
                    updatedGroupName == option.name ||
                    nameExists(updatedGroupName)
                  }
                  sx={{
                    color: "#666",
                    fontWeight: 500,
                    fontSize: 12,
                    background: "#fff",
                    borderRadius:2,
                    border: "1px solid #ddd",
                  }}
                  onClick={handleEditName}
                />
              </Box>
            )}
  
            <Box sx={{ flexGrow: 1 }} />
  
            {(hovered) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "flex-end",
                }}
              >
  
                <Chip
                  icon={<EditNote sx={{ color: "#999" }} />}
                  label={editName ? "Cancel" : "Edit"}
                  size="small"
                  sx={{
                    color: "#666",
                    fontWeight: 500,
                    fontSize: 12,
                    borderRadius:2,
                    background: "transparent",
                    border: "1px solid #ddd",
                  }}
                  onClick={handleEditClick}
                />
            {type != "group" && (
                <Chip
                
                  icon={<Add sx={{ color: "#999" }} />}
                  label="Add Group"
                  size="small"
                  sx={{
                    color: "#666",
                    fontWeight: 500,
                    fontSize: 12,
                    borderRadius: 2,
                    zIndex: 1000,
                    background: "transparent",
                    border: "1px solid #ddd",
                  }}
                  onClick={handleAddGroupClick}
                />
              )
            }
              </Box>
            )}
          </Box>
        </li>
        {addGroup && (
          <li
            {...props}
            style={{
              zIndex: 2000,
            }}
            // onClick={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            //   // focusTextField();
  
            //   console.log("clicked");
            // }}
          >
            <ClickAwayListener onClickAway={() => setAddGroup(false)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  ml: 4,
                  p: 1,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  focusTextField();
                }}
              >
                <Add sx={{ color: "#999" }} />
  
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                    p: 1,
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    borderRadius: 2,
                    flexGrow: 1,
                  }}
                >
                  <TextField
                    fullWidth
                    variant="standard"
                    size="small"
                    placeholder="New Group"
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    inputProps={{
                      style: { fontSize: 14, fontWeight: 600, color: "#666" },
                      ref: inputRef,
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                  <Box sx={{ flexGrow: 1 }} />
                  <Chip
                  id="new-group-save-button"
                    icon={<SaveOutlined sx={{ color: "#999" }} />}
                    label="Save"
                    size="small"
                    disabled={
                      newGroupName.trim() == "" ||
                      newGroupName == option.name ||
                      nameExists(newGroupName)
                    }
                    sx={{
                      color: "#666",
                      fontWeight: 500,
                      fontSize: 12,
                      zIndex: 1000,
                      borderRadius:2,
                      background: "#fff",
                      border: "1px solid #ddd",
                    }}
                    onClick={handleNewGroup}
                  />
                </Box>
              </Box>
            </ClickAwayListener>
          </li>
        )}
      </>
  
    );
  
  });


