import {
  Box,
  Grid,
  Typography,
  Card,
  createTheme,
  ButtonBase,
  Tabs,
  Tab,
  Avatar,
  Button,
  Paper,
  IconButton,
  CardMedia,
  Chip,
  Grow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Popper,
  ClickAwayListener,
  Skeleton,
  Icon,
  Tooltip,
  Divider,
  Popover,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  Add,
  AnalyticsOutlined,
  ArrowBackIos,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  AssessmentOutlined,
  BackHand,
  BackspaceOutlined,
  BarChartOutlined,
  CalculateOutlined,
  Check,
  Close,
  CommentOutlined,
  Download,
  ExpandMore,
  FastRewindOutlined,
  FavoriteBorder,
  FilterList,
  Fullscreen,
  FullscreenExitOutlined,
  Group,
  LineAxisOutlined,
  LocalActivityRounded,
  NumbersOutlined,
  OpenInNew,
  OpenInNewOutlined,
  Percent,
  PieChartOutlined,
  Redo,
  Refresh,
  Replay,
  Reply,
  SendOutlined,
  ShowChartOutlined,
  ShowChartRounded,
  StackedLineChartOutlined,
  TimelineOutlined,
  VisibilityOutlined,
  CopyAllOutlined,
  Work,
  CheckCircle,
  Chat,
  ArchiveOutlined,
  CloseFullscreenTwoTone,
  CloseOutlined,
  SentimentNeutralOutlined,
} from "@mui/icons-material";
import { siftsyTheme } from "./siftsytheme";
import { useUser, UserButton } from "@clerk/clerk-react";
import {
  formatData,
  formatNumber,
  formatPlatform,
  UTCtoLocal,
  formatFullDate,
  estimateViews
} from "./Helpers";
import CommentView from "./CommentView";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import HelperFunctions from "./HelperFunctions";
import { initial, set } from "lodash";
import html2canvas from "html2canvas";
import Footer from "./Footer";
import ContentMetrics from "./ContentMetrics";
import SelectChips from "./SelectChips";
import ExportMetricsModal from "./ExportMetricsModal";
import VibeCheckSection from "./VibeCheckSection";
import { BarChart } from "recharts";
import "./TableStyles.css";
import ContentTagPicker from "./ContentTagPicker";
import GroupPicker from "./GroupPicker";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import { ArrowPathIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon, ArrowTopRightOnSquareIcon, ChartBarIcon, ChartBarSquareIcon, ChatBubbleBottomCenterTextIcon, ChatBubbleLeftRightIcon, DocumentDuplicateIcon, EyeIcon, EyeSlashIcon, FolderArrowDownIcon, HeartIcon, LinkIcon, RectangleGroupIcon, Square2StackIcon, Square3Stack3DIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { useAuth } from "@clerk/clerk-react";
import { DevicePhoneMobileIcon, CalendarDaysIcon } from "@heroicons/react/24/solid";
let ENV = process.env.REACT_APP_ENV;


export default function GroupView(props) {
  const Functions = HelperFunctions();
  const { teamData } = props;
  
  // Define LOOKBACK here so it has access to teamData
  const LOOKBACK = teamData?.plan?.lookback || 6; // Default to 6 months if plan lookback is not specified
  const defaultLookback = 24;

  console.log("LOOKBACK", LOOKBACK);

  const { isLoaded, isSignedIn, user } = useUser();

  const {
    viewportHeight,
    viewportWidth,
    selectedPostData,
    resetPosts,
    refreshData,
    loading,
    backToDash,
    groupView,
    setGroupView,
    selectedGroups,
    forwardToPost,
    mobileTab,
    selectedPost,
    softRefresh,
    selectedTags,
    selectedAccounts,
    selectedPostStatus,
    setSelectedPostStatus,
    showArchivedModal,
    setShowArchivedModal
    
  
  } = props;

  const metricsRef = React.useRef(null);

  const small = viewportHeight < 900;
  const [localGroupView, setLocalGroupView] = useState(groupView); // Add this new state

  React.useEffect(() => {
    setLocalGroupView(groupView);
  }, [groupView]);

  const [empty, setEmpty] = React.useState(true);
  const [selectedComments, setSelectedComments] = React.useState([]);
  const [exportMetrics, setExportMetrics] = React.useState(false);
  const [fullTable, setFullTable] = React.useState(() => {
    const savedFullTable = localStorage.getItem('fullTable');
    return savedFullTable ? JSON.parse(savedFullTable) : false;
  });  const [exporting, setExporting] = React.useState(false);
  const [selectedPostRows, setSelectedPostRows] = React.useState([]);
  const [minimizeMetrics, setMinimizeMetrics] = React.useState(() => {
    const savedMinimizeMetrics = localStorage.getItem('minimizeMetrics');
    return savedMinimizeMetrics ? JSON.parse(savedMinimizeMetrics) : false;
  });  const updateGroupView = React.useCallback((view) => {
    requestAnimationFrame(() => {
      setGroupView(view);
    });
  }, []);

  const [hoveredThumbnail, setHoveredThumbnail] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const tableRef = React.useRef(null);
  const [copied, setCopied] = React.useState(false);
  const [selectedRowsRef, setSelectedRowsRef] = React.useState([]); // Ref to store the current selection
  const [openPopper, setOpenPopper] = React.useState(false);
  const [quickFilterText, setQuickFilterText] = React.useState("");
  const [commentData, setCommentData] = React.useState([]);
  const [hideNoComments, setHideNoComments] = React.useState(false);

  const [quickFilterTextInput, setQuickFilterTextInput] = React.useState("");

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setQuickFilterText(quickFilterTextInput);
    }, 300); // 300ms delay

    return () => clearTimeout(timeoutId);
  }, [quickFilterTextInput]);

  const handleRowHover = (target, thumbnail) => {
    setHoveredThumbnail(thumbnail);
    setAnchorEl(target);
  };

  const handleClosePopper = () => {
    setHoveredThumbnail(null);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setEmpty(!selectedPostData || selectedPostData.length === 0);
    // updateGroupView("comments");
  }, [selectedPostData]);

  const goToPost = React.useCallback(
    (postId) => {
      forwardToPost(postId);
      // updateGroupView("comments");
    },
    [teamData]
  );

  const handleMinimizeMetrics = React.useCallback((state) => {
    setMinimizeMetrics(state);
    setFullTable(state);
    localStorage.setItem('minimizeMetrics', JSON.stringify(state));
    localStorage.setItem('fullTable', JSON.stringify(state));
  }, []);

  const handleSetFullTable = React.useCallback((state) => {
    setFullTable(state);
    setMinimizeMetrics(state);
    localStorage.setItem('fullTable', JSON.stringify(state));
    localStorage.setItem('minimizeMetrics', JSON.stringify(state));
  }, []);

const handleExportMetrics = React.useCallback((state) => {
    setExportMetrics(state);
  } , []);

  const handleSelectedComments = React.useCallback((comments) => {
    setSelectedComments(comments);
  }, []);

  const handleSelectedPostRows = React.useCallback((rows) => {
    setSelectedPostRows(rows);
  }, []);

  const onSelectionChanged = React.useCallback(() => {
    const selectedRows = tableRef.current.api.getSelectedRows();
    setSelectedRowsRef(selectedRows);
  }, []);

  const copyTableDataToClipBoard = () => {
    if (!tableRef.current || !tableRef.current.api) {
      console.error('Table reference is not set or API is not available.');
      return;
    }

    const headers = [
      'Account', 
      'Username', 
      'Campaigns', 
      'Tags', 
      'Vibe Score',
      'Sentiment',
      'Positive Sentiment %',
      'Neutral Sentiment %',
      'Negative Sentiment %',
      'Platform', 
      'Views', 
      'Comments', 
      'Likes', 
      'Shares', 
      'Saves'
    ];
    
    const allNodes = tableRef.current.api.getRenderedNodes();
    const allData = allNodes.map(node => ({
      account: node.data.account,
      username: node.data.username,
      campaigns: node.data.groupIds
        .map(group => {
          const foundGroup = teamData.groups.find(g => g.id === group);
          return foundGroup ? foundGroup.name : "";
        })
        .filter(Boolean)
        .join(", "),
        tags: JSON.parse(node.data.tags).join(", "),
        vibeScore: node.data.vibeScore.toFixed(1),
        sentiment: `${node.data.sentimentData?.positivePercentage?.toFixed(0) + "%"}/${node.data.sentimentData?.neutralPercentage?.toFixed(0) + "%"}/${node.data.sentimentData?.negativePercentage?.toFixed(0) + "%"}`,
        positiveSentimentPercentage: node.data.sentimentData?.positivePercentage?.toFixed(0) + "%",
        neutralSentimentPercentage: node.data.sentimentData?.neutralPercentage?.toFixed(0) + "%",
        negativeSentimentPercentage: node.data.sentimentData?.negativePercentage?.toFixed(0) + "%",
      platform: node.data.platform,
      views: node.data.views,
      comments: node.data.comments,
      likes: node.data.likes,
      shares: node.data.shares,
      saves: node.data.saves
    }));

    const csvData = [
      headers,
      ...allData.map(item => [
        item.account,
        item.username,
        item.campaigns,
        item.tags,
        item.vibeScore,
        item.sentiment,
        item.positiveSentimentPercentage,
        item.neutralSentimentPercentage,
        item.negativeSentimentPercentage,
        item.platform,
        item.views,
        item.comments,
        item.likes,
        item.shares,
        item.saves
      ])
    ];
    
    const csvString = csvData.map(row => Object.values(row).join(',')).join('\n');
    
    navigator.clipboard.writeText(csvString).catch(err => {
      console.error('Error copying data: ', err);
    });

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  
  const isMobile = viewportWidth < 900;
  // Add these state variables at the top of the component
  const [isInitializing, setIsInitializing] = React.useState(true);
  const [dataReady, setDataReady] = React.useState(false);

  // Add this useEffect to handle initialization
  React.useEffect(() => {
    if (selectedPostData && Array.isArray(selectedPostData) && !loading && teamData) {
      // Wait a small delay to ensure all data processing is complete
      const timer = setTimeout(() => {
        setIsInitializing(false);
        setDataReady(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [selectedPostData, loading, teamData]);

  return (!dataReady) ? (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1em",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      m: 4,
    }}
  >
    <Grid container spacing={4}>
      <Grid item xs={12} sx={{ height: 120 }}>
        <Skeleton
          variant="rectangular"
          height={100}
          sx={{ borderRadius: 4 }}
          animation="wave"
        />
      </Grid>

      <Grid item xs={12} md={4} sx={{ height: 420 }}>
        <Skeleton
          variant="rectangular"
          height={400}
          sx={{ borderRadius: 4 }}
          animation="wave"
        />
      </Grid>
      <Grid item xs={12} md={8} sx={{ height: 1340 }}>
        <Skeleton
          variant="rectangular"
          height={300}
          sx={{ borderRadius: 4 }}
          animation="wave"
        />
        <br />
        <Skeleton
          variant="rectangular"
          height={800}
          sx={{ borderRadius: 4 }}
          animation="wave"
        />
      </Grid>
    </Grid>
  </Box>
  ) : (selectedPostData && selectedPostData.length === 0) ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(30vh)",
        gap: 2
      }}
    >
      <Typography variant="h6" align="center" sx={{ color: "#888" }}>
        No posts here... yet!
      </Typography>
      <Typography variant="body1" align="center" sx={{ color: "#888" }}>
        Add posts or adjust filter settings above to view posts.
      </Typography>
      <Button
        variant="contained"
        sx={{
          borderRadius: 30,
          textTransform: "none",
          fontWeight: 600,
          padding: "10px 20px",
          fontSize: 20,
        }}
        startIcon={<Add />}
        onClick={() => props.openAddNew("post", true)}
      >
        Add Posts
      </Button>
    </Box>
  ) : (
    <Grid container spacing={2} sx={{ p: isMobile ? .5 : 2, pt: 0 }}>
      <Grid
        item
        xs={12}
        md={minimizeMetrics? 12 : 5}
        lg={minimizeMetrics? 12 : 4}
        sx={{ pb: minimizeMetrics ? 0 :5, display: !isMobile ? "inline-block" : "none" }}
      > 
        <>
          {!minimizeMetrics && (
            <Box
              sx={{
                display: "flex",
                height: isMobile ? "40px" : "50px",
                flexDirection: "row",
              }}
            >
              <Tabs>
                <Tab
                  label={
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                      <ChartBarIcon style={{height:20, color:"#4728c4"}}/>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4728c4",
                          fontWeight: 700,
                          textTransform: "none",
                          fontSize: isMobile ? 14 : 16,
                        }}
                      >
                        Metrics
                      </Typography>
                    </Box>
                  }
                  value={true}
                />
              </Tabs>
            </Box>
          )}
          <Grow in={true}>
            <Box
              sx={
         
                !minimizeMetrics ? {
                  overflowY: "scroll",
                  height: "fit-content",
                  "&::-webkit-scrollbar": {
                    width: "0px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "transparent",
                  }}
                  :
                  {
                   
                  }
                
              }
            >
              <ContentMetrics
              setMinimizeMetrics={handleMinimizeMetrics}
              minimizeMetrics={minimizeMetrics}
                handleExportMetrics={handleExportMetrics}
                selectedPostData={selectedPostData}
                teamData={teamData}
                refreshData={refreshData}
                isMobile={isMobile}
                softRefresh={softRefresh}
                small={viewportHeight < 900}
                viewportHeight={viewportHeight}
              />

              {!small && !minimizeMetrics &&
              <>
              <Footer />
              <br />
              <br /> <br />
              <br /> <br />
              <br />
              <ExportMetricsModal
                open={exportMetrics}
                onClose={() => setExportMetrics(false)}
                selectedPostData={selectedPostData}
                teamData={teamData}
                selectedGroups={selectedGroups}
              />
              </>
              }
            </Box>
          </Grow>
        </>
      </Grid>
      <Grid item xs={12} md={fullTable || minimizeMetrics ? 12 : 7} lg={fullTable || minimizeMetrics ? 12 : 8}>
        <Box sx={{ height: "fit-content" }}>
          <Box
            sx={{
              display: "flex",
              height: isMobile ? "40px" : "50px",
              flexDirection: "row",
            }}
          >
            {selectedPostData.length > 1 || isMobile ? (
              <Tabs
                value={localGroupView}
                onChange={(event, newValue) => {
                  // Update local state immediately
                  setLocalGroupView(newValue);
                  // Then update parent state
                  setGroupView(newValue);
                }}
                sx={{ height: isMobile ? "40px" : "50px",
                
                 }}
                 TabIndicatorProps={{
                  style: { 
                    top: 0,
                  }
                }}
                //place indicator on top of the tab
       
              >
                {isMobile && (
                  <Tab
                    value="metrics"
                    label={
                      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>

                      <ChartBarIcon style={{height:20, color: groupView == "metrics" ? "#4728c4" : "#888"}}/>
                      <Typography
                        variant="body1"
                        sx={{
                          color: groupView == "metrics" ? "#4728c4" : "#888",
                          fontWeight: 700,
                          textTransform: "none",
                          fontSize: isMobile ? 14 : 16,
                          height: 45,
                        }}
                      >
                        {" "}
                        Metrics
                      </Typography>
                      </Box>
                    }
                  />
                )}
                <Tab
                  label={
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>

                    <ChatBubbleLeftRightIcon style={{height:20, color: groupView == "comments" ? "#4728c4" : "#888"}}/>
                    <Typography
                      variant="body1"
                      sx={{
                        color: groupView == "comments" ? "#4728c4" : "#888",
                        fontWeight: 700,
                        textTransform: "none",
                        fontSize: isMobile ? 14 : 16,
                        height: 45,
                      }}
                    >
                      Comments
                      {/* {`Comments (${formatNumber(
                        selectedPostData
                          .map((post) => post.data.engagement ? post.data.engagement.comments : 0)
                          .reduce((a, b) => a + b, 0)
                      )})`} */}
                    </Typography>
                    </Box>
                  }
                  value="comments"
                />
                {selectedPostData.length > 1 && (
                  <Tab
                    label={
                      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>

                      <Square3Stack3DIcon style={{height:20, color: groupView == "posts" ? "#4728c4" : "#888"}}/>
                      <Typography
                        variant="body1"
                        sx={{
                          color: groupView == "posts" ? "#4728c4" : "#888",
                          fontWeight: 700,
                          textTransform: "none",
                          fontSize: isMobile ? 14 : 16,
                          height: 45,
                        }}
                      >
                        Posts
                        {" "}
                        {selectedPostData.length > 0
                          ? `(${selectedPostData.length})`
                          : ""}
                      </Typography>
                      </Box>
                    }
                    value="posts"
                  />
                )}
              </Tabs>
            ) : (
              <Box sx={{ display: "flex", height: isMobile ? "40px" : "50px" }}>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    ml: 2,
                    color: "#4728c4",
                    fontWeight: 700,
                    textTransform: "none",
                    fontSize: isMobile ? 14 : 16,
                  }}
                >
                  {`Comments (${formatNumber(
                    selectedPostData
                      .map((post) => post.data.engagement.comments)
                      .reduce((a, b) => a + b, 0)
                  )})`}
                </Typography>
              </Box>
            )}



            <Box sx={{ flexGrow: 1 }} />

            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!isMobile && groupView === "posts" && selectedPostData.length > 1 && (
                  <>

                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{border: "1px solid #ccc", borderRadius: 2, background:"#fff"}}
                    placeholder="Search Posts"
                    value={quickFilterTextInput}
                    onChange={(e) => setQuickFilterTextInput(e.target.value)}
                  />
                  <Tooltip title="Export Post Data as CSV">
                    <IconButton
                      sx={{
                        width:35,
                        height:35,
                      }}
                      onClick={() => {
                        if (
                          tableRef &&
                          tableRef.current &&
                          tableRef.current.api
                        ) {
                          tableRef.current.api.exportDataAsCsv();
                        }
                      }}
                    >
                      <FolderArrowDownIcon style={{height:20, color:"#5C6078"}}/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy Data">
                    {copied ?

                    <IconButton disabled 
                    sx={{
                      width:35,
                      height:35,
                    }}>
                      <CheckCircle color="#089616"/>
                    </IconButton>

                    :
                  <IconButton onClick={copyTableDataToClipBoard}
                  sx={{
                    width:35,
                    height:35,
                  }}>
                    <DocumentDuplicateIcon style={{height:20, color:"#5C6078"}}/>
                  </IconButton>
                    }
                </Tooltip>
                  </>
                )}
          
      
                <Tooltip title="Refresh Data">
                  <IconButton onClick={refreshData} 
                  sx={{
                    width:35,
                    height:35,
                  }}>
                    <ArrowPathIcon style={{height:20, color:"#5C6078"}}/>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {viewportWidth > 900 ? (
              <Tooltip title={fullTable ? "Back to Default View" : "Maximize View"}>
                <IconButton 
                  onClick={() => handleSetFullTable(!fullTable)}
                  sx={{
                    mt:1,
                    width:35,
                    height:35,
                    position: 'relative'
                  }}>
                  {fullTable ? 
                        <ArrowsPointingInIcon style={{
                          height: 20, 
                          color: minimizeMetrics ? "#4728c4" : "#5C6078",
                          filter: minimizeMetrics ? "drop-shadow(0 0 4px rgba(71, 40, 196, 0.7))" : "none"
                        }}/>
                    : 
                    <ArrowsPointingOutIcon style={{height:20, color: minimizeMetrics ? "#4728c4" : "#5C6078"}}/>
                  }
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>

          {/* {groupView == "metrics" && viewportWidth < 900 && ( */}
            <Box
              sx={{
                display: groupView == "metrics" && viewportWidth < 900 ? "block" : "none",
                overflowY: "scroll",
                height:
                  viewportWidth < 900
                    ? "calc(100vh - 195px)"
                    : "calc(100vh - 224px)",
              }}
            >
              <ContentMetrics
                handleExportMetrics={handleExportMetrics}
                selectedPostData={selectedPostData}
                teamData={teamData}
                refreshData={refreshData}
                isMobile={isMobile}
              />
              <Footer />
              <br />
              <br /> <br />
              <br /> <br />
              <br />
              <ExportMetricsModal
                open={exportMetrics}
                onClose={() => setExportMetrics(false)}
                selectedPostData={selectedPostData}
                teamData={teamData}
                selectedGroups={selectedGroups}
              />
            </Box>
            {/* <SelectChipsPopper
                selectedRows={selectedRowsRef.current}
                teamData={teamData}
                refreshData={refreshData}
                softRefresh={softRefresh}
                viewportWidth={viewportWidth}
                openPopper={openPopper}
                
              /> */}
            <Box sx={{display: (groupView === "comments" || selectedPostData.length == 1) ? "block" : "none"}}>
            <CommentView
              teamData={teamData}
              viewportHeight={viewportHeight}
              viewportWidth={viewportWidth}
              selectedPostData={selectedPostData}
              selectedComments={selectedComments}
              handleSelectedComments={handleSelectedComments}
              selectedGroups={selectedGroups}
              goToPost={goToPost}
              fullTable={fullTable}
              selectedTags={selectedTags}
              selectedAccounts={selectedAccounts}
              commentData={commentData}
              setCommentData={setCommentData}
              isMobile={isMobile}
      
            /> 
            </Box>
{/* 
          {groupView === "comments" ? (

            
          ) : (
            <>
           

              <Paper elevation={0} sx={{ width: "100%", overflowX: "auto" }}>
                {loading && (
                  <Box
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                      height: viewportHeight - 300,
                      borderRadius: 2,
                      border: "1px solid #ccc",
                      scroll: "none",
                    }}
                  >
                    <Box sx={{ height: 55, background: "#eee" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: viewportHeight / 2,
                        gap: 2,
                        p: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress size={100} />
                    </Box>
                  </Box>
                  
                )}
              </Paper>
            </>
          ) */}
          {(groupView == "posts" && selectedPostData.length > 1) && (
      
                    <PostTable
                    LOOKBACK={LOOKBACK}
                    quickFilterText={quickFilterText}
                    selectedPostRows={selectedPostRows}
                    setSelectedPostRows={handleSelectedPostRows}
                      selectedPostData={selectedPostData}
                      teamData={teamData}
                      refreshData={refreshData}
                      softRefresh={softRefresh}
                      hideNoComments={hideNoComments}
                      tableRef={tableRef}
                      onSelectionChanged={onSelectionChanged}
                      isMobile={isMobile}
                      goToPost={goToPost}
                      viewportHeight={viewportHeight}
                      viewportWidth={viewportWidth}
                      height={fullTable ? "calc(100vh - 325px)" : "calc(100vh - 230px)"}
                      loading={loading}
                      copyTableDataToClipBoard={copyTableDataToClipBoard}
                      selectedPostStatus={selectedPostStatus}
                      setSelectedPostStatus={setSelectedPostStatus}
                      showArchivedModal={showArchivedModal}
                      setShowArchivedModal={setShowArchivedModal}
                    />
                  )}
              
        </Box>
      </Grid>
    </Grid>
  );
}

function dateComparator(date1, date2) {
  var date1Timestamp = new Date(date1).getTime();
  var date2Timestamp = new Date(date2).getTime();

  if (date1Timestamp < date2Timestamp) {
    return -1;
  } else if (date1Timestamp > date2Timestamp) {
    return 1;
  } else {
    return 0;
  }
}

const MiniScorer = ({ score, size }) => {
  let color =
    score >= 7
      ? "#089616"
      : score >= 5
      ? "#74d52e"
      : score >= 3
      ? "#ffb93d"
      : "#fb3e3e";

  const svgStyle = {
    height: "140px",
    width: "140px",
    position: "absolute",
    transform: "rotate(-85deg)",
    fill: "transparent",
    top: 0,
    left: 0,
  };

  const circleStyle = {
    fill: "transparent",
  };

  const progressBorderStyle = {
    stroke: color,
    strokeWidth: "20px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    // Change number value to shift the progress bar // 130 = 100, 440 = 0
    strokeDashoffset: score == 10 ? 130 : 440 - (310 / 10) * score + 8,
  };

  const trackStyle = {
    stroke: color,
    opacity: 0.5,
    strokeWidth: "20px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    strokeDashoffset: "130", // Change number value to shift the progress bar
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        position: "relative",
      }}
    >
      {score ?
      <Avatar
        sx={{
          width: size ? size : "40px",
          height: size ? size : "40px",
          color: color,
          background: "transparent",
          fontSize: size ? size / 2 : "1rem",
          fontWeight: 900,
        }}
      >
        {score}
      </Avatar>
      :
      <Typography sx={{ fontSize: size ? size / 2 : 12, m: 1, fontWeight: 700, color:"#aaa" }}>
        N/A
      </Typography>
}

      {/* <svg style={svgStyle}>
        <circle id="track" cx="80" cy="60" r="50" style={trackStyle} />
        <circle
          id="progress-border"
          cx="80"
          cy="60"
          r="50"
          style={{ ...circleStyle, ...progressBorderStyle }}
        />
      </svg> */}
    </Box>
  );
};







const PostTable = (props) => {

  const { LOOKBACK, setSelectedPostStatus, showArchivedModal, setShowArchivedModal, quickFilterText, selectedPostStatus, viewportWidth, hideNoComments, selectedPostData, loading, height, setSelectedPostRows, selectedPostRows, teamData, refreshData, softRefresh, tableRef, onSelectionChanged, isMobile, goToPost, viewportHeight } = props;

  const Functions = HelperFunctions();

  const [hoveredPost, setHoveredPost] = React.useState(null);
  const [hoverAnchorEl, setHoverAnchorEl] = React.useState(null);

  let defaultLookback = 24;


  const [openMultiplePopper, setOpenMultiplePopper] = React.useState(false);
  const [rowData, setRowData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [hideAnchorEl, setHideAnchorEl] = React.useState(null);
  const [showHideConfirm, setShowHideConfirm] = React.useState(false);
  const [postsToHide, setPostsToHide] = React.useState([]);
  const [campaignAnchorEl, setCampaignAnchorEl] = React.useState(null);
  const [showCampaignEdit, setShowCampaignEdit] = React.useState(false);
  const [postsToEdit, setPostsToEdit] = React.useState([]);

  const calculateEngagementRate = (post) => {
    let views = post.data.engagement.views || 0;
    let comments = post.data.engagement.comments || 0;
    let likes = post.data.engagement.likes || 0;
    let shares = post.data.engagement.shares || 0;
    let saves = post.data.engagement.saves || 0;
    let platform = post.data.platform;

    if (platform === "linkedin" && comments >= 0 && likes >= 0 && shares >= 0 && saves >= 0) {
      views = estimateViews(likes, comments, shares, saves, platform);
    }

    let rate = (((likes + comments + shares + saves) * 100) / views);
    return isNaN(rate) || !isFinite(rate) ? -1 : rate;
  };

  const handleArchivePosts = async (postIds) => {
    if (!postIds || postIds.length === 0) {
      console.error("No posts selected for archiving");
      return;
    }

    try {
      const response = await Functions.updatePostsStatus(postIds, "archived");
      handleResetSelectedRows();
      setRowData(prevRowData => 
        prevRowData.filter(row => !postIds.includes(row.id))
      );
      softRefresh();
    } catch (error) {
      console.error("Error archiving posts:", error);
    }
  };

  const handleShowPosts = async (postIds) => {
    if (!postIds || postIds.length === 0) {
      console.error("No posts selected for showing");
      return;
    }

    try {
      const response = await Functions.updatePostsStatus(postIds, "complete");
      handleResetSelectedRows();
      softRefresh();
      setRowData(prevRowData => 
        prevRowData.filter(row => !postIds.includes(row.id))
      );
    } catch (error) {
      console.error("Error showing posts:", error);
    }
  };

  // Add this helper function to reset selections
  const handleResetSelectedRows = () => {
    if (tableRef.current && tableRef.current.api) {
      tableRef.current.api.deselectAll();
    }
    setSelectedRows([]);
    setSelectedPostRows([]);
  };

  React.useEffect(() => {

    // Define getRowData as a separate function
    const getRowData = async (postId) => {
      try {
        const post = selectedPostData.find(p => p.id === postId);

        const vibeCheck = post?.data?.vibeCheckData ? post?.data?.vibeCheckData : await Functions.getPostVibeCheck(postId);
        const numComments = post?.data?.engagement?.comments ? post?.data?.engagement?.comments : 0;

        if (hideNoComments && numComments === 0) {
          return null;
        }

        return {
          id: post.id,
          teamId: post.teamId,
          avatar: post.data.userAvatar,
          thumbnail: post.data.thumbnail,
          account: post.data.creatorName,
          username: post.data.username,
          vibeScore: vibeCheck?.vibeScore,
          sentimentData: vibeCheck?.sentimentData,
          relevanceData: vibeCheck?.relevanceData,
          consensusData: vibeCheck?.consensusData,
          positiveSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.positivePercentage : null,
          neutralSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.neutralPercentage : null,
          negativeSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.negativePercentage : null,
          siftedAt: post.createdAt ? formatFullDate(post.createdAt) : "",
          tags: post.data.tags,
          platform: formatData(post.data.platform),
          postType: post.data.postType,
          date: formatFullDate(post.data.postedAt),
          engagementRate: calculateEngagementRate(post),
          views: post.data.engagement.views > 0
            ? post.data.engagement.views
            : estimateViews(
                post.data.engagement.likes,
                post.data.engagement.comments,
                post.data.engagement.shares,
                post.data.engagement.saves,
                post.data.platform
              ),
          comments: numComments,
          likes: post.data.engagement.likes,
          shares: post.data.engagement.shares,
          saves: post.data.engagement.saves ? post.data.engagement.saves : 0,
          groupIds: post.groupIds ? post.groupIds : [],
          link: post.link ? post.link : "",
          status: post.status,
        };
      } catch (error) {
        console.error("Error fetching row data:", error);
        return null; // Return null for failed fetch
      }
    }

    async function fetchData() {
      if (!selectedPostData || selectedPostData.length === 0) {
        return;
      }
  
      const rowData = await Promise.all(selectedPostData.map(post => getRowData(post.id)));
      setRowData(rowData);
    }

      
    fetchData();

  }, [selectedPostData, hideNoComments]);

  const gridOptions = useMemo(() => ({
    enableCellTextSelection: true,
    ensureDomOrder: true,
    suppressColumnVirtualisation: true,
    animateRows: false,
    rowHeight: isMobile ? 50 : 60,
    getRowStyle: (params) => Functions.isPostTooOld(defaultLookback, params.data.date) ? { opacity: 0.5 } : null,
    onRowClicked: (event) => {
      const rowElement = event.event.target.closest('.ag-row');
      if (rowElement) {
        // Toggle popper - close if clicking same row, open if clicking new row
        if (hoveredPost?.id === event.data.id) {
          setHoveredPost(null);
          setHoverAnchorEl(null);
        } else {
          setHoveredPost(event.data);
          setHoverAnchorEl(rowElement);
        }
      }
    },
    // Add onBodyScroll handler
    onBodyScroll: () => {
      // Close popper when scrolling
      setHoveredPost(null);
      setHoverAnchorEl(null);
    },
  }), [hoveredPost]); // Add hoveredPost to dependencies

  const handleSelectionChanged = () => {
    const selectedRows = tableRef.current.api.getSelectedRows(); // Extract selected rows
    // console.log("selectedRows", selectedRows);
    setSelectedRows(selectedRows); // Update the selected rows state
      };

  const columnDefs = useMemo(() => {
    return [
      {
        field: "status",
        headerName: "",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        width: 50,
      },
      {
        headerName: "",
        pinned: "left",
        sortable: false,
        cellRenderer: (props) => {
          const isTooOld = Functions.isPostTooOld(defaultLookback, props.data.date);
          return (
            <Box sx={{width: 30, height:30}}>
              <Tooltip title={isTooOld ? "Upgrade to analyze this post." : "Analyze this post"}>
            <IconButton
              sx={{ height: 25, width: 25 }}
              disabled={isTooOld}
              onClick={() => {
                goToPost(props.data.id);
              }}
            >
                                <ArrowTopRightOnSquareIcon style={{ height: 20, width: 20, color:"#5C6078" }} />

              <OpenInNewOutlined
                sx={{ height: 20, width: 20 }}
              />
            </IconButton>
            </Tooltip>
            </Box>
          );
        },
        width: 60,
      },
      {
        field: "account",
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          return params.value ? params.value.replace(/\s\s+/g, " ") : "";
        },
        width: 220,
        cellRenderer: (props) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                width: 200,
                overflow: "hidden"
              }}
            >
              <Avatar
                sx={{ width: 30, height: 30, flexShrink: 0 }}
                src={props.data.avatar}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 0,
                  flex: 1,
                  overflow: "hidden",
                  width: "calc(100% - 46px)" // 30px avatar + 16px gap
                }}
              >
                <Typography
                  noWrap
                  sx={{ 
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#333",
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {props.data.account}
                </Typography>
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ 
                    fontSize: 13,
                    color: "#666",
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  @{props.data.username?.replace("@", "")}
                </Typography>
              </Box>
            </Box>
          );
        },
      },
      {
        field: 'link',
        headerName: 'Link',
        width: 65,
        cellRenderer: (params) => {
          return params.value ? (
            <Typography
              component="a"
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#4728c4',
                textDecoration: 'none',
                fontWeight: 500,
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Link
            </Typography>
          ) : null;
        }
      },
      {
        field: "groupIds",
        headerName: "Campaigns",
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          return Array.isArray(params.value) ? params.value.map((group) => {
            return teamData.groups.find(
              (g) => g.id == group
            ) ? teamData.groups.find((g) => g.id == group).name : "No Campaign";
          }) : [];
        },
        cellRenderer: (props) => {
          const isTooOld = Functions.isPostTooOld(defaultLookback, props.data.date);
          return <Box sx={{width: 180, pointerEvents: isTooOld ? 'none' : 'auto'}}>
            <GroupPicker
              data={{groupIds: props.data.groupIds}}
              refreshData={refreshData}
              softRefresh={softRefresh}
              teamData={teamData}
              cellView
              postIds={[props.data.id]}
              disabled={isTooOld}
              disablePopper
            />
          </Box>;
        },
        width: 180,
      },
      
      {
        field: "tags",
        headerName: "Tags",
        width: 180,
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          if (params.value) {
            try {
              return JSON.parse(params.value).map((tag) => tag);
            } catch (e) {
              console.error("Error parsing tags:", e);
              return []; // Return an empty array or a fallback value
            }
          }
          return []; // Return an empty array if params.value is undefined
        },
        cellRenderer: (props) => {
          const isTooOld = Functions.isPostTooOld(defaultLookback, props.data.date);

          return <Box sx={{ width: 180, pointerEvents: isTooOld ? 'none' : 'auto' }}><ContentTagPicker teamData={teamData}
            refreshData={refreshData}
            post={{ id: props.data.id, data: props.data }}
            softRefresh={softRefresh}
            cellView
            disablePopper
          /></Box>
        },
      },
      {
        field: "vibeScore",
        cellStyle: { display: "flex", alignItems: "center" },
        valueGetter: (params) => params.data.vibeScore != null ? params.data.vibeScore.toFixed(1) : 0,
        cellRenderer: (props) => {
          return (props.data.comments > 0 && props.data.vibeScore && props.data.vibeScore > 0) ? (
            <MiniScorer score={props.data.vibeScore.toFixed(1)} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                position: "relative",
                p: 1,
              }}
            >
              <Tooltip title="Vibe Score is not available for posts with no comments.">
              <Typography
                align="center"
                sx={{
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#888",
                }}
              >
                N/A
              </Typography>
              </Tooltip>
            </Box>
          );
        },
        width: 110,
      },
      {
        field: "sentimentData",
        headerName: "Sentiment",
        sortable:false,
        valueGetter: (params) => 
          (params.data.sentimentData?.positivePercentage || params.data.sentimentData?.neutralPercentage || params.data.sentimentData?.negativePercentage) ?
          `${params.data.sentimentData.positivePercentage.toFixed(0)}% / ${params.data.sentimentData.neutralPercentage.toFixed(0)}% / ${params.data.sentimentData.negativePercentage.toFixed(0)}%` 
          : "N/A",
        cellRenderer: (props) => {
          return <Box sx={{display: "flex", flexDirection: "row", gap: .5, alignItems: "center", height:55}}>
                     {props.data.sentimentData?.positivePercentage > 0 && 
          <Chip 
            sx={{ 
              fontWeight: 'bold', 
              color: '#fff', 
              backgroundColor: '#6dbf75', 
              fontWeight: 500,
              paddingTop: 0.3,
              width: `${Math.max(props.data.sentimentData?.positivePercentage, 30)}%` // Set width based on percentage with a minimum of 10%
            }}  
            label={props.data.sentimentData?.positivePercentage?.toFixed(0) + "%"} 
            color="success" 
            size="small" 
          />
        }
        {props.data.sentimentData?.neutralPercentage > 0 && 
          <Chip 
            sx={{ 
              fontWeight: 'bold', 
              color: '#fff',
              backgroundColor: '#f9b64e', 
              fontWeight: 500,
              paddingTop: 0.3,
              width: `${Math.max(props.data.sentimentData?.neutralPercentage, 30)}%` // Set width based on percentage with a minimum of 10%
            }} 
            label={props.data.sentimentData?.neutralPercentage?.toFixed(0) + "%"} 
            color="warning" 
            size="small" 
          />
        }
        {props.data.sentimentData?.negativePercentage > 0 && 
          <Chip 
            sx={{ 
              fontWeight: 'bold', 
              backgroundColor: '#fc8b8f',
              fontWeight: 500,
              paddingTop: 0.3,
              width: `${Math.max(props.data.sentimentData?.negativePercentage, 30)}%` // Set width based on percentage with a minimum of 10%
            }} 
            label={props.data.sentimentData?.negativePercentage?.toFixed(0) + "%"} 
            color="error" 
            size="small" 
          />
        }
          </Box>;
          ;
        },
        width: 180,
      },
      {
        field: "positiveSentimentPercentage",
        headerName: "Positive % (Sentiment)",
        valueGetter: (params) => params.data.positiveSentimentPercentage != null ? params.data.positiveSentimentPercentage.toFixed(0) : null, // Ensure it can be accessed for export
        hide: false,
        width: 80,
        sortable:true,
        comparator: (a, b) => {
          if (a === null) return -1; // Move null to the beginning
          if (b === null) return 1;  // Move null to the beginning
          return a - b; // Standard comparison for numbers
        },
        cellRenderer: (props) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5, alignItems: "center", height: 55 }}>
              <Typography fontSize={props.data.positiveSentimentPercentage != null ? 16 : 12} color={props.data.negativeSentimentPercentage != null ? "#000" : "#888"}>
              {props.data.positiveSentimentPercentage != null 
                  ? props.data.positiveSentimentPercentage.toFixed(0) + "%" 
                  : "N/A"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "neutralSentimentPercentage",
        headerName: "Neutral % (Sentiment)",
        valueGetter: (params) => params.data.neutralSentimentPercentage != null ? params.data.neutralSentimentPercentage.toFixed(0) : null,
        hide: false,
        width: 80,
        sortable:true,
        comparator: (a, b) => {
          if (a === null) return -1; // Move null to the beginning
          if (b === null) return 1;  // Move null to the beginning
          return a - b; // Standard comparison for numbers
        },

        cellRenderer: (props) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5, alignItems: "center", height: 55 }}>
              <Typography fontSize={props.data.neutralSentimentPercentage != null ? 16 : 12} color={props.data.neutralSentimentPercentage != null ? "#000" : "#888"}>
              {props.data.neutralSentimentPercentage != null 
                  ? props.data.neutralSentimentPercentage?.toFixed(0) + "%" 
                  : "N/A"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "negativeSentimentPercentage",
        headerName: "Negative % (Sentiment)",
        valueGetter: (params) => params.data.negativeSentimentPercentage != null ? params.data.negativeSentimentPercentage.toFixed(0) : null,
        hide: false,
        width: 80,
        sortable:true,
        comparator: (a, b) => {
          if (a === null) return -1; // Move null to the beginning
          if (b === null) return 1;  // Move null to the beginning
          return a - b; // Standard comparison for numbers
        },
        cellRenderer: (props) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5, alignItems: "center", height: 55 }}>
              <Typography fontSize={props.data.negativeSentimentPercentage != null ? 16 : 12} color={props.data.negativeSentimentPercentage != null ? "#000" : "#888"}>
                {props.data.negativeSentimentPercentage != null 
                  ? props.data.negativeSentimentPercentage?.toFixed(0) + "%" 
                  : "N/A"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "platform",
        width: 110,
        cellRenderer: (props) => {
          return (
            <Typography variant="body1" fontWeight={600}>
              {props.data.platform}
            </Typography>
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "engagementRate",
        valueFormatter: (params) => {
          return params.value < 0 ? "N/A" : params.value?.toFixed(1) + "%";
        },
        cellRenderer: (props) => {
          // engagement rate as percentage
          return (
            //check if engagement rate is a number or is infinte
          
               <Typography variant={props.data.engagementRate < 0 ? "body2" : "body1"}
                 fontWeight={600} color={props.data.engagementRate < 0 ? "#888" : "#000"}>
              {props.data.engagementRate < 0 ? "N/A" : props.data.engagementRate?.toFixed(1) + "%"}{props.data.platform == "LinkedIn" 
              // || (props.data.platform == "Instagram" && props.data.postType == "feed")
               ? "*" : ""}
              </Typography>
            
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 130,
         
        // cellRenderer: (props) => {
        //   return <MiniScorer score={props.data.vibeScore} />;
        // },
        // width:110
      },
      {
        field: "views",
        editable: true,
        cellRenderer: (props) => {
          return props.data.views == 0 || isNaN(props.data.views) || !isFinite(props.data.views)

           ? (
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 14,
                color: "#888",
              }}
            >
              N/A
            </Typography>
          ) 

          : (
            <Typography>
              {formatNumber(props.data.views)}{props.data.platform == "LinkedIn" 
              // || (props.data.platform == "Instagram" && props.data.postType == "feed") 
              ? "*" : ""}
            </Typography>
          )
        
        },
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          return params.value == 0 || isNaN(params.value) || !isFinite(params.value) ? "N/A" : params.value.toFixed(0);
        },
        width: 110,
      },
      {
        field: "likes",
        cellRenderer: (props) => {
          return (  isNaN(props.data.likes) || !isFinite(props.data.likes)

          ? (
           <Typography
             sx={{
               fontWeight: 600,
               fontSize: 12,
               color: "#888",
             }}
           >
             N/A
           </Typography>
         ) : (
            <Typography>
              {formatNumber(props.data.likes)}
            </Typography>
         )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "comments",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.comments) || !isFinite(props.data.comments)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.comments)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "shares",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.shares) || !isFinite(props.data.shares)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.shares)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "saves",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.saves) || !isFinite(props.data.saves)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.saves)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {field:"postType",
        valueGetter: (params) => params.data.postType == "reel" ? "REELS" : params.data.postType == "short" ? "SHORTS" : params.data.postType.toUpperCase(),
      cellRenderer: (props) => {
        return <Chip 
        sx={{
          fontWeight: 600,
          fontSize: 10,
          color: "#333",
        }}
        label={(props.data.postType == "reel" ? "Reels" : props.data.postType == "short" ? "shorts" : props.data.postType).toUpperCase()} />;
      },
    
      cellStyle: { display: "flex", alignItems: "center" },
      width: 110,
      },
      {
        field: "date",
        sortable: true,
        comparator: dateComparator,
        cellRenderer: (props) => {
          return <Typography>{props.data.date}</Typography>;
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field:"siftedAt",
        sortable: true,
        comparator: dateComparator,
        cellRenderer: (props) => {
          return <Typography>{props.data.siftedAt}</Typography>;
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
        sort:"desc"
      },
      // {
      //   field: "status",
      //   headerName: "Status",
      //   width: 120,
      //   cellRenderer: (props) => {
      //     return (
      //       <Chip
      //         label={props.data.status || "Active"}
      //         size="small"
      //         sx={{
      //           backgroundColor: props.data.status === "archived" ? "#f5f5f5" : "#e3f2fd",
      //           color: props.data.status === "archived" ? "#666" : "#2196f3",
      //           fontWeight: 500,
      //           fontSize: 12,
      //           height: 24
      //         }}
      //       />
      //     );
      //   }
      // },
    ]
  }, []);

  // Add handleStatusChange callback
  const handleStatusChange = React.useCallback((newStatus) => {
    setSelectedPostStatus(newStatus);
    setSelectedPostRows([]); // Clear selection first
    refreshData(); // Refresh data immediately
  }, [setSelectedPostStatus, setSelectedPostRows, refreshData]);

  const handleHideClick = (event, posts) => {
    if (!event?.currentTarget) {
      setShowHideConfirm(false);
      setHideAnchorEl(null);
      setPostsToHide([]);
      return;
    }
    setHideAnchorEl(event.currentTarget);
    setShowHideConfirm(true);
    setPostsToHide(posts);
  };

  const handleCampaignClick = (event, posts) => {
    // Close popper if no event (clickaway) or no posts
    if (!event?.currentTarget || !posts?.length) {
      setShowCampaignEdit(false);
      setCampaignAnchorEl(null);
      setPostsToEdit([]);
      return;
    }
    
    // Open popper with selected posts
    setCampaignAnchorEl(event.currentTarget);
    setShowCampaignEdit(true);
    setPostsToEdit(posts);
  };

 return loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={viewportHeight - (isMobile ? 200 : 300)}>
      <CircularProgress />
    </Box>
  ) : rowData.length === 0 ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={viewportHeight - (isMobile ? 200 : 300)}>
      <CircularProgress />
    </Box>
  ) : selectedPostData.length === 0 ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={viewportHeight - (isMobile ? 200 : 300)}>
      <Typography>No data available</Typography>
    </Box>
  ) : (
    <Box position="relative">
      {selectedRows.length > 0 && (
        <Box 
          sx={{ 
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 999,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            background: 'rgba(255, 255, 255, 0.98)',
            padding: '16px 20px',
            borderRadius: '12px',
            boxShadow: '0 8px 32px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.05)',
            backdropFilter: 'blur(8px)',
            width:"60%",
            maxWidth: 300,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="subtitle2" sx={{ color: '#666', fontWeight: 600 }}>
              {selectedRows.length} {selectedRows.length === 1 ? 'post' : 'posts'} selected
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                if (tableRef.current && tableRef.current.api) {
                  tableRef.current.api.deselectAll();
                }
              }}
              sx={{
                color: '#666',
                '&:hover': {
                  background: 'rgba(0,0,0,0.04)'
                }
              }}
            >
              <CloseOutlined style={{height:16, width:16}} />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {!selectedRows.some(row => Functions.isPostTooOld(defaultLookback, row.date)) && (
              <>
                <Box>
                  <Typography variant="caption" sx={{ color: '#666', mb: 0.5, display: 'block' }}>
                    Campaigns
                  </Typography>
                  <GroupPicker
                    data={{
                      groupIds: selectedRows.reduce((commonIds, row) => {
                        const currentIds = row.groupIds || [];
                        if (commonIds.length === 0) return currentIds;
                        return commonIds.filter(id => currentIds.includes(id));
                      }, [])
                    }}
                    refreshData={refreshData}
                    softRefresh={softRefresh}
                    teamData={teamData}
                    postIds={selectedRows.map(row => row.id)}
                    multiEdit={true}
                  />
                </Box>

                <Box>
                  <Typography variant="caption" sx={{ color: '#666', mb: 0.5, display: 'block' }}>
                    Tags
                  </Typography>
                  <ContentTagPicker
                    teamData={teamData}
                    refreshData={refreshData}
                    post={{ 
                      id: selectedRows[0].id,
                      data: {
                        // Parse tags properly for each row before reducing
                        tags: JSON.stringify(selectedRows.reduce((commonTags, row) => {
                          // Parse tags if they're a string, or use empty array if null/undefined
                          const currentTags = typeof row.tags === 'string' ? JSON.parse(row.tags) : row.tags || [];
                          // For first row, use its tags as initial set
                          if (commonTags.length === 0) return currentTags;
                          // For subsequent rows, only keep tags that exist in both arrays
                          return commonTags.filter(tag => currentTags.includes(tag));
                        }, []))
                      }
                    }}
                    softRefresh={softRefresh}
                    multiEdit={true}
                    postIds={selectedRows.map(row => row.id)}
                  />
                </Box>
              </>
            )}

            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              {selectedPostStatus === "archived" && (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleShowPosts(selectedRows.map(row => row.id))}
                  startIcon={<EyeIcon style={{ height: 16, width: 16, color: '#fff' }} />}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 600,
                    background: '#4728c4',
                    '&:hover': {
                      background: '#3b1fa8'
                    }
                  }}
                >
                  Show Posts
                </Button>
              )}
              
              {selectedPostStatus === "complete" && (
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={(e) => handleHideClick(e, selectedRows.map(row => row.id))}
                  startIcon={<EyeSlashIcon style={{ height: 16, width: 16, color: '#666' }} />}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 600,
                    borderColor: '#ddd',
                    color: '#666',
                    '&:hover': {
                      borderColor: '#999',
                      background: 'rgba(0,0,0,0.02)'
                    }
                  }}
                >
                  Hide Posts
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}

      <div
        className={`ag-theme-quartz data-table`}
        style={{
          height: viewportHeight - (isMobile ? 200 : 350),
          width: "100%",
          background: "transparent",
        }}
        id="data-table"
      >
        <AgGridReact
          ref={tableRef}
          cacheQuickFilter={true}
          suppressCellSelection={true}
          suppressRowClickSelection={false}
          rowSelection="multiple" 
          suppressScrollOnNewData={true}
          deltaRowDataMode={true}
          getRowId={(params) => params.data.id}
          animateRows={true} 
          quickFilterText={quickFilterText}
          enableRangeSelection={true}
          clipboardDeliminator={','}
          defaultCsvExportParams={{
            fileName: `Siftsy-Export-${new Date().toLocaleDateString()}`,
            columnSeparator: ",",
          }}
          gridOptions={gridOptions}
          rowData={rowData.filter((post) => post.status === selectedPostStatus)}
          columnDefs={columnDefs}
          rowBuffer={20}
          maxBlocksInCache={10}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={2}
          infiniteInitialRowCount={100}
          pagination={true}
          paginationPageSize={isMobile ? null : 100}
          suppressPaginationPanel={false}
          paginationAutoPageSize={isMobile ? true : false}
          paginationNumberFormatter={(params) => 
            isMobile ? params.value : params.value.toLocaleString()
          }
          onSelectionChanged={(params) => {
            if (!showHideConfirm) { // Only update selection if popper is not showing
              handleSelectionChanged(params);
            }
          }}
        />
      </div>

      {showHideConfirm && (
        <Popper
          open={showHideConfirm}
          anchorEl={hideAnchorEl}
          placement="bottom"
          style={{ zIndex: 1000 }}
        >
          <ClickAwayListener onClickAway={() => handleHideClick(null, [])}>
            <Fade in={showHideConfirm}>
              <Paper 
                elevation={3}
                sx={{ 
                  p: 3,
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: 2.5, 
                  maxWidth: 340,
                  borderRadius: 2,
                  border: '1px solid rgba(0,0,0,0.08)',
                  background: 'rgba(255,255,255,0.98)',
                  backdropFilter: 'blur(8px)'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <EyeSlashIcon style={{ height: 24, width: 24, color: '#666' }} />
                  <Typography 
                    variant="h6"
                    sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      color: '#333'
                    }}
                  >
                    Hide {postsToHide.length} {postsToHide.length === 1 ? 'post' : 'posts'}?
                  </Typography>
                </Box>
                
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: '#666',
                    lineHeight: 1.5 
                  }}
                >
                  Hidden posts will be moved to your archive and can be restored later.
                </Typography>

                <Box sx={{ 
                  display: 'flex', 
                  gap: 1.5, 
                  justifyContent: 'flex-end',
                  pt: 1 
                }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleHideClick(null, [])}
                    size="medium"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 600,
                      px: 2,
                      borderColor: '#ddd',
                      color: '#666',
                      '&:hover': {
                        borderColor: '#999',
                        background: 'rgba(0,0,0,0.02)'
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleArchivePosts(postsToHide);
                      handleHideClick(null, []);
                    }}
                    size="medium"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 600,
                      px: 2,
                      background: '#4728c4',
                      '&:hover': {
                        background: '#3b1fa8'
                      }
                    }}
                  >
                    Hide Posts
                  </Button>
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        </Popper>
      )}

      {showCampaignEdit && (
        <Popper
          open={showCampaignEdit}
          anchorEl={campaignAnchorEl}
          placement="bottom"
          style={{ zIndex: 1000 }}
        >
          <ClickAwayListener onClickAway={() => handleCampaignClick(null, [])}>
            <Fade in={showCampaignEdit}>
              <Paper 
                elevation={3}
                sx={{ 
                  p: 3,
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: 2.5, 
                  maxWidth: 340,
                  borderRadius: 2,
                  border: '1px solid rgba(0,0,0,0.08)',
                  background: 'rgba(255,255,255,0.98)',
                  backdropFilter: 'blur(8px)'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <RectangleGroupIcon style={{ height: 24, width: 24, color: '#666' }} />
                  <Typography 
                    variant="h6"
                    sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      color: '#333'
                    }}
                  >
                    Edit campaigns for {postsToEdit.length} {postsToEdit.length === 1 ? 'post' : 'posts'}
                  </Typography>
                </Box>
                
                <GroupPicker
                  data={{groupIds: []}} // Start with empty selection
                  refreshData={refreshData}
                  softRefresh={softRefresh}
                  teamData={teamData}
                  postIds={postsToEdit}
                  multiEdit={true}
                />

                <Box sx={{ 
                  display: 'flex', 
                  gap: 1.5, 
                  justifyContent: 'flex-end',
                  pt: 1 
                }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleCampaignClick(null, [])}
                    size="medium"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 600,
                      px: 2,
                      borderColor: '#ddd',
                      color: '#666',
                      '&:hover': {
                        borderColor: '#999',
                        background: 'rgba(0,0,0,0.02)'
                      }
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        </Popper>
      )}

      <ArchivedPostsModal 
        LOOKBACK={LOOKBACK}
        open={showArchivedModal}
        onClose={() => setShowArchivedModal(false)}
        teamData={teamData}
        refreshData={refreshData}
        softRefresh={softRefresh}
        viewportHeight={viewportHeight}
        viewportWidth={viewportWidth}
        isMobile={isMobile}
      />

<PostDetailsPopper
        anchorEl={hoverAnchorEl}
        post={hoveredPost}
        onClose={() => {
          setHoveredPost(null);
          setHoverAnchorEl(null);
        }}
      />

    </Box>
  );

}


//create a popper with a selectchips component for when rows are selected in the table
//this will allow users to update the campaign of multiple posts at once

const ArchivedPostsModal = ({ LOOKBACK, open, onClose, teamData, refreshData, softRefresh, viewportHeight, viewportWidth, isMobile }) => {
  const Functions = HelperFunctions();
  const tableRef = React.useRef(null);
  const [rowData, setRowData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [quickFilterText, setQuickFilterText] = React.useState("");
  const [quickFilterTextInput, setQuickFilterTextInput] = React.useState("");

  const defaultLookback = 24;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setQuickFilterText(quickFilterTextInput);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [quickFilterTextInput]);

  const gridOptions = useMemo(() => ({
    enableCellTextSelection: true,
    ensureDomOrder: true,
    suppressColumnVirtualisation: true,
    animateRows: false,
    rowHeight: 60,
    getRowStyle: (params) => Functions.isPostTooOld(defaultLookback, params.data.date) ? { opacity: 0.5 } : null,
  }), []);

  React.useEffect(() => {
    if (open) {
      loadArchivedPosts();
    }
  }, [open, teamData.archivedPosts]);

  const loadArchivedPosts = () => {
    setLoading(true);
    try {
      const formattedData = teamData.archivedPosts.map(post => ({
        id: post.id,
        avatar: post.data.userAvatar,
        thumbnail: post.data.thumbnail,
        account: post.data.creatorName,
        username: post.data.username,
        platform: formatData(post.data.platform),
        postType: post.data.postType,
        date: formatFullDate(post.data.postedAt),
        views: post.data.engagement.views,
        comments: post.data.engagement.comments,
        likes: post.data.engagement.likes,
        shares: post.data.engagement.shares,
        saves: post.data.engagement.saves,
        groupIds: post.groupIds || [],
        link: post.link || "",
        status: post.status,
      }));
      setRowData(formattedData);
    } catch (error) {
      console.error("Error formatting archived posts:", error);
    }
    setLoading(false);
  };

  const handleShowPosts = async (postIds) => {
    try {
      await Functions.updatePostsStatus(postIds, "complete");
      setRowData(prevData => prevData.filter(row => !postIds.includes(row.id)));
      refreshData();
    } catch (error) {
      console.error("Error showing posts:", error);
    }
  };

  const handleSelectionChanged = () => {
    if (tableRef.current && tableRef.current.api) {
      const selectedNodes = tableRef.current.api.getSelectedNodes();
      setSelectedRows(selectedNodes.map(node => node.data));
    }
  };

  const columnDefs = [
    {
      field: "status",
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50
    },
    {
      field: "account",
      headerName: "Account",
      cellStyle: { display: "flex", alignItems: "center" },
      width: 220,
      cellRenderer: (props) => (
        <Box sx={{ 
          display: "flex", 
          flexDirection: "row", 
          alignItems: "center", 
          gap: 2, 
          width: 200,
          overflow: "hidden"
        }}>
          <Avatar sx={{ width: 30, height: 30, flexShrink: 0 }} src={props.data.avatar} />
          <Box sx={{ 
            display: "flex", 
            flexDirection: "column", 
            minWidth: 0,
            flex: 1,
            overflow: "hidden",
            width: "calc(100% - 46px)" // 30px avatar + 16px gap
          }}>
            <Typography
              noWrap
              sx={{ 
                fontSize: 14,
                fontWeight: 500,
                color: "#333",
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {props.data.account}
            </Typography>
            <Typography
              variant="body2"
              noWrap
              sx={{ 
                fontSize: 13,
                color: "#666",
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              @{props.data.username?.replace("@", "")}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 65,
      cellRenderer: (params) => {
        return params.value ? (
          <Typography
            component="a"
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: '#4728c4',
              textDecoration: 'none',
              fontWeight: 500,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Link
          </Typography>
        ) : null;
      }
    },
    {
      field: "platform",
      headerName: "Platform",
      width: 110,
      cellRenderer: (props) => (
        <Typography variant="body1" fontWeight={600} noWrap>
          {props.data.platform}
        </Typography>
      ),
    },
    {
      field: "postType",
      headerName: "Type",
      width: 110,
      cellRenderer: (props) => (
        <Chip 
          sx={{
            fontWeight: 600,
            fontSize: 10,
            color: "#333",
            maxWidth: "100%",
            "& .MuiChip-label": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }
          }}
          label={(props.data.postType == "reel" ? "Reels" : props.data.postType == "short" ? "shorts" : props.data.postType).toUpperCase()} 
        />
      ),
    },
    {
      field: "views",
      headerName: "Views",
      width: 110,
      cellRenderer: (props) => (
        <Typography noWrap>
          {formatNumber(props.data.views)}
        </Typography>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 110,
      cellRenderer: (props) => (
        <Typography noWrap>
          {formatNumber(props.data.comments)}
        </Typography>
      ),
    },
    {
      field: "likes",
      headerName: "Likes",
      width: 110,
      cellRenderer: (props) => (
        <Typography noWrap>
          {formatNumber(props.data.likes)}
        </Typography>
      ),
    },
    {
      field: "shares",
      headerName: "Shares",
      width: 110,
      cellRenderer: (props) => (
        <Typography noWrap>
          {formatNumber(props.data.shares)}
        </Typography>
      ),
    },
    {
      field: "saves",
      headerName: "Saves",
      width: 110,
      cellRenderer: (props) => (
        <Typography noWrap>
          {formatNumber(props.data.saves)}
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      cellRenderer: (props) => (
        <Typography noWrap>
          {props.data.date}
        </Typography>
      ),
    },
    {
      field: "groupIds",
      headerName: "Campaigns",
      cellStyle: { display: "flex", alignItems: "center" },
      valueFormatter: (params) => {
        return Array.isArray(params.value) ? params.value.map((group) => {
          return teamData.groups.find(
            (g) => g.id == group
          ) ? teamData.groups.find((g) => g.id == group).name : "No Campaign";
        }) : [];
      },
      cellRenderer: (props) => {
        const isTooOld = Functions.isPostTooOld(defaultLookback, props.data.date);
        return <Box sx={{width: 180, pointerEvents: isTooOld ? 'none' : 'auto'}}>
          <GroupPicker
            data={{groupIds: props.data.groupIds}}
            refreshData={refreshData}
            softRefresh={softRefresh}
            teamData={teamData}
            cellView
            postIds={[props.data.id]}
            disabled={isTooOld}
            disablePopper
          />
        </Box>;
      },
      width: 180,
    },
  ];

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxHeight: viewportHeight * 0.8
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        pb: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p:2 }}>
          <EyeSlashIcon style={{ height: 20, width: 20, color: '#666' }} />
          <Typography variant="h6">Archived Posts ({teamData.archivedPosts.length})</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, border: '1px solid #ddd', borderRadius: 2, p:1 }}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search Posts"
            value={quickFilterTextInput}
            onChange={(e) => setQuickFilterTextInput(e.target.value)}
            sx={{ 
              width: 200,
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                background: '#fff',
                
                '& fieldset': {
                  borderColor: '#ddd',
                },
                
                '&:hover fieldset': {
                  borderColor: '#999',
                },
              }
            }}
          />
          
          <IconButton 
            onClick={onClose} 
            size="small"
          >
            <CloseOutlined />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={400}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ position: 'relative', height: 500 }}>
            {selectedRows.length > 0 && (
              <Box sx={{ 
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 999,
                display: "flex",
                gap: 2,
                background: 'white',
                padding: '12px 16px',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                alignItems: "center"
              }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#666',
                    fontWeight: 500
                  }}
                >
                  {selectedRows.length} {selectedRows.length === 1 ? 'post' : 'posts'} selected
                </Typography>

                {/* Only show GroupPicker if none of the selected posts are too old */}
                {!selectedRows.some(row => Functions.isPostTooOld(defaultLookback, row.date)) && (
                  <GroupPicker
                  data={{
                    groupIds: selectedRows.reduce((commonIds, row) => {
                      const currentIds = row.groupIds || [];
                      // For first row, use its groupIds as initial set
                      if (commonIds.length === 0) return currentIds;
                      // For subsequent rows, only keep ids that exist in both arrays
                      return commonIds.filter(id => currentIds.includes(id));
                    }, [])
                  }} 
                    refreshData={refreshData}
                    softRefresh={softRefresh}
                    teamData={teamData}
                    postIds={selectedRows.map(row => row.id)}
                    multiEdit={true}
                  />
                )}

                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleShowPosts(selectedRows.map(row => row.id))}
                  startIcon={<EyeIcon style={{ height: 16, width: 16, color: '#fff' }} />}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 600,
                    px: 3,
                    py: 1,
                    background: '#4728c4',
                    borderRadius: '6px',
                    '&:hover': {
                      background: '#3b1fa8'
                    }
                  }}
                >
                  Unhide
                </Button>
                <IconButton 
                  onClick={() => {
                    if (tableRef.current && tableRef.current.api) {
                      tableRef.current.api.deselectAll();
                      setSelectedRows([]);
                    }
                  }}
                  size="small"
                  sx={{
                    color: '#666',
                    '&:hover': {
                      background: 'rgba(0,0,0,0.04)'
                    }
                  }}
                >
                  <CloseOutlined style={{ height: 16, width: 16 }} />
                </IconButton>
              </Box>
            )}

            <div className="ag-theme-quartz" style={{ height: '98%', width: '98%', margin: 'auto' }}>
              <AgGridReact
                ref={tableRef}
                rowData={rowData}
                columnDefs={columnDefs}
                gridOptions={gridOptions}
                rowSelection="multiple"
                suppressRowClickSelection={false}
                onSelectionChanged={handleSelectionChanged}
                pagination={true}
                paginationPageSize={10}
                cacheQuickFilter={true}
                quickFilterText={quickFilterText}
              />
            </div>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};


const PostDetailsPopper = ({ anchorEl, post, onClose }) => {
  if (!post) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>

    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="right"
      style={{ zIndex: 1000 }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ]}
    >
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            width: '100%',
            maxWidth: 480,
            minWidth: 400,
            borderRadius: 3,
            border: '1px solid rgba(0,0,0,0.08)',
            background: 'rgba(255,255,255,0.98)',
            backdropFilter: 'blur(8px)',
            overflow: 'hidden',
            position: 'relative'
          }}
        >
          {/* Close button */}
          <IconButton 
            size="small" 
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor: 'rgba(255,255,255,0.9)',
              backdropFilter: 'blur(4px)',
              zIndex: 1,
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.95)',
              }
            }}
          >
            <CloseOutlined sx={{ fontSize: 16 }} />
          </IconButton>

          {/* Left side - Thumbnail */}
          <Box sx={{ width: '40%', position: 'relative' }}>
            {post.thumbnail ? (
              <Box sx={{ position: 'relative', height: '100%', minHeight: 320 }}>
                <img
                  src={post.thumbnail}
                  alt="Post thumbnail"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute'
                  }}
                />
                {post.link && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.open(`/team/${post.teamId}/post/${post.id}`, '_self');
                    }}
                    startIcon={<ArrowTopRightOnSquareIcon style={{ height: 16 }} />}
                    sx={{
                      position: 'absolute',
                      bottom: 16,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'white',
                      textTransform: 'none',
                      fontSize: 13,
                      fontWeight: 600,
                      color: '#4728c4',
                      '&:hover': {
                        color: 'white',
                      }
                    }}
                  >
                    Post
                  </Button>
                )}
              </Box>
            ) : (
              <Box 
                sx={{ 
                  width: '100%',
                  minHeight: 320,
                  backgroundColor: '#f5f5f5',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2
                }}
              >
                <Typography variant="body2" color="text.secondary">No thumbnail</Typography>
                {post.link && (
                            <Button
                            variant="contained"
                            onClick={() => {
                              window.open(`/team/${post.teamId}/post/${post.id}`, '_self');
                            }}
                           
                            startIcon={<ArrowTopRightOnSquareIcon style={{ height: 16 }} />}
                            sx={{
                              position: 'absolute',
                              bottom: 16,
                              left: '50%',
                              transform: 'translateX(-50%)',
                              backgroundColor: 'white',
                              textTransform: 'none',
                              fontSize: 13,
                              fontWeight: 600,
                              color: '#4728c4',
                              '&:hover': {
                                color: 'white',
                              }
                            }}
                          >
                            Post
                          </Button>
                )}
              </Box>
            )}
          </Box>

          {/* Right side - Content */}
          <Box sx={{ 
            width: '60%', 
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
            {/* Creator Info */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              pb: 1,
              borderBottom: '1px solid rgba(0,0,0,0.08)'
            }}>
              <Avatar 
                src={post.avatar} 
                sx={{ 
                  width: 32, 
                  height: 32,
                  border: '2px solid #fff',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }} 
              />
              <Box>
                <Typography variant="subtitle2" fontWeight={600} sx={{ lineHeight: 1.2 }}>
                  {post.account}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  @{post.username?.replace("@", "")}
                </Typography>
              </Box>
              
              <Box>
                {post.link && (
                  <IconButton
                    href={post.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(71, 40, 196, 0.08)'
                      }
                    }}
                  >
                    <LinkIcon style={{ height: 16 }} />
                  </IconButton>
                )}
              </Box>
            </Box>

            {/* Metrics Grid */}
            <Grid container spacing={1}>
              {/* Vibe Score */}
              {post.comments > 0 && post.vibeScore && post.vibeScore > 0 && (
                <Grid item xs={12}>
                  <Box sx={{ 
                    borderRadius: 1.5, 
                    transition: 'all 0.2s',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.04)'
                    }
                  }}>
                    <Typography variant="caption" color="text.secondary">
                      Siftsy Score
                    </Typography>
                    <MiniScorer score={post.vibeScore.toFixed(1)} size={50} />
                  </Box>
                </Grid>
              )}

              {/* Sentiment Breakdown */}
              {post.comments > 0 && post.sentimentData && (
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: .5, alignItems: "center", mb:.5 }}>
                    <Typography variant="caption" color="text.secondary">Sentiment</Typography>
                  </Box>

                  <Box sx={{ 
                    borderRadius: 1.5, 
                    transition: 'all 0.2s',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.04)'
                    },            
                  }}>
                   

                    <Box sx={{ display: "flex", flexDirection: "row", gap: .5, alignItems: "center" }}>
                      {post.sentimentData?.positivePercentage > 0 && 
                        <Chip 
                          sx={{ 
                            fontWeight: 'bold', 
                            color: '#fff', 
                            backgroundColor: '#6dbf75', 
                            fontWeight: 500,
                            paddingTop: 0.3,
                            width: `${Math.max(post.sentimentData?.positivePercentage, 30)}%`
                          }}  
                          label={post.sentimentData?.positivePercentage?.toFixed(0) + "%"} 
                          color="success" 
                          size="small" 
                        />
                      }
                      {post.sentimentData?.neutralPercentage > 0 && 
                        <Chip 
                          sx={{ 
                            fontWeight: 'bold', 
                            color: '#fff',
                            backgroundColor: '#f9b64e', 
                            fontWeight: 500,
                            paddingTop: 0.3,
                            width: `${Math.max(post.sentimentData?.neutralPercentage, 30)}%`
                          }} 
                          label={post.sentimentData?.neutralPercentage?.toFixed(0) + "%"} 
                          color="warning" 
                          size="small" 
                        />
                      }
                      {post.sentimentData?.negativePercentage > 0 && 
                        <Chip 
                          sx={{ 
                            fontWeight: 'bold', 
                            backgroundColor: '#fc8b8f',
                            fontWeight: 500,
                            paddingTop: 0.3,
                            width: `${Math.max(post.sentimentData?.negativePercentage, 30)}%`
                          }} 
                          label={post.sentimentData?.negativePercentage?.toFixed(0) + "%"} 
                          color="error" 
                          size="small" 
                        />
                      }
                    </Box>
                  </Box>
                </Grid>
              )}
           
              {/* Engagement Metrics */}
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                flexWrap: 'wrap',
                m:1,
                mt:2
              }}>
                {[
                  { label: <EyeIcon style={{ height: 12, width: 12, color: '#666' }} />, value: post.views },
                  { label: <HeartIcon style={{ height: 12, width: 12, color: '#666' }} />, value: post.likes },
                  { label: <ChatBubbleBottomCenterTextIcon style={{ height: 12, width: 12, color: '#666' }} />, value: post.comments },
                  { label: <ArrowPathIcon style={{ height: 12, width: 12, color: '#666' }} />, value: post.shares },
                  ...(post.saves > 0 ? [{ label: <HeartIcon style={{ height: 12, width: 12, color: '#666' }} />, value: post.saves }] : [])
                ].map((metric) => (
                  <Box 
                    key={metric.label}
                    sx={{ 
                      p: .5,
                      flex: '1 1 auto',
                      minWidth: '40px',
                      borderRadius: 1.5, 
                      transition: 'all 0.2s',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.04)'
                      }
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {metric.label}
                    </Typography>
                    <Typography variant="h6" fontWeight={600} fontSize={16}>
                      {formatNumber(metric.value)}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {/* Additional Details */}
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row', // Changed from 'column' to 'row'
                gap: 1,
                pt: 1.5,
                mt: 'auto',
                width: '100%'
              }}>
                {[
                  { label: 'Platform', value: post.platform, icon: <DevicePhoneMobileIcon style={{ height: 12, width: 12, color: '#666' }} /> }, 
                  // { label: 'Type', value: post.postType.toUpperCase(), icon: <Square2StackIcon style={{ height: 12, width: 12, color: '#666' }} /> }, 
                  { label: 'Posted', value: post.date, icon: <CalendarDaysIcon style={{ height: 12, width: 12, color: '#666' }} /> }
                ].map((detail) => (
                  <Box 
                    key={detail.label}
                    sx={{ 
                      display: 'flex',
                      flex: 1, // Added to make boxes equal width
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 0.75,
                      borderRadius: 1,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.04)'
                      }
                    }}
                  >
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}> 
                      {detail.icon}
                    </Box> */}
                    <Typography 
                      variant="body2"
                      sx={{ 
                        fontWeight: 600,
                      }}
                    >
                      {detail.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Box>
        </Paper>
     
    </Popper>
    </ClickAwayListener>
  );
};  