import React, { useState } from "react";
import {
    Autocomplete,
  Box,
  ButtonBase,
  Chip,
  ClickAwayListener,
  FormControl,
  IconButton,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Check, Close } from "@mui/icons-material";
import HelperFunctions from "./HelperFunctions";

const GroupPicker = (props) => {
    const { deleteable, cellView, postIds, refreshData, softRefresh, teamData, disablePopper = false } = props;
    const Functions = HelperFunctions();
    const [newGroupName, setNewGroupName] = useState("");
    const cellRef = React.useRef(null);
  
    const [cellViewOpen, setCellViewOpen] = useState(false);
  
    let groupOptions = props.teamData.groups ? props.teamData.groups : [];
  
    const [selectedGroups, setSelectedGroups] = useState([]);

    React.useEffect(() => {
        if (props.data.groupIds && props.teamData.groups) {
            const groups = props.teamData.groups.filter(
                (group) => props.data.groupIds.includes(group.id)
            );
            setSelectedGroups(groups);
        } else {
            setSelectedGroups([]);
        }
    }, []);


    const handleSelectedGroupChange = async (event, newValue) => {
        setSelectedGroups(newValue);
        updatePostsGroups(postIds, newValue.map((group) => group.id));
    };

    const updatePostsGroups = async (postIds, groupIds) => {
      let update = await Functions.updatePostsGroups(postIds, groupIds);
      softRefresh();
    };

    
     

  const chipStyles = {
    backgroundColor: "#f0f2f5",
    color: "#4a5056",
    fontWeight: 500,
    fontSize: "0.813rem",
    height: 32,
    borderRadius: "16px",
    '&:hover': {
        backgroundColor: "#e4e6e9"
    }
  };

  const autocompleteComponent = (
    <Autocomplete
    //   freeSolo
      multiple
      clearOnEscape={false}
      clearIcon={null}
      fullWidth
      value={selectedGroups}
      onChange={handleSelectedGroupChange}
      options={groupOptions}
      getOptionLabel={(option) => option.name}
      renderTags={(value, getTagProps) => (
        <Box sx={{ marginBottom: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {value.map((option, index) => (
                <Chip
                    sx={chipStyles}
                    key={option.id}
                    label={option.name}
                    deleteIcon={deleteable ? 
                        <Close sx={{ fontSize: 16, color: '#6b7280' }} /> : null}
                    onDelete={deleteable ? 
                        () => setSelectedGroups(
                            selectedGroups.filter((item) => item !== option)
                        ) : null}
                    {...getTagProps({ index })}
                />
            ))}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="standard"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            sx: {
                padding: '4px 8px',
                '&::placeholder': {
                    color: '#9ca3af',
                    fontWeight: 500
                }
            }
          }}
          sx={{
            border: "0px",
            background: "transparent",
            '& .MuiInputBase-root': {
                fontSize: '0.875rem',
                color: '#4b5563',
            }
          }}
          placeholder="Select campaigns or type in a new one"
          onKeyDown={(event) => {
            if (event.key === 'Backspace' && event.target.value.trim() === '') {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />

      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Chip
            sx={{
                ...chipStyles,
                backgroundColor: selected ? "#e4e6e9" : "white",
                border: 'none'
            }}
            label={option.name}
            icon={selected ? <Check sx={{ fontSize: 16, color: '#4a5056' }} /> : null}
          />
        </li>
      )}
    />
  );

  return (
  
  <Box sx={{ 
    display: "flex", 
    flexDirection: "row", 
    gap: 1, 
    width: "100%",
    minHeight: 40
  }} ref={cellRef}>
  {cellView && !cellViewOpen ? (
    <ButtonBase 
        onClick={() => !disablePopper && setCellViewOpen(true)}
        fullWidth
        sx={{ 
            width: "100%",
            justifyContent: 'flex-start',
            padding: '4px 8px',
            borderRadius: 1,
            cursor: disablePopper ? 'default' : 'pointer',
            '&:hover': {
                backgroundColor: disablePopper ? 'transparent' : '#f9fafb'
            }
        }}
    >
      <>
        <Box
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
        >
              {props.data.groupIds && props.data.groupIds.length > 0 && 
              props.teamData.groups.find(
                (group) => group.id == props.data.groupIds[0]
              ) && props.teamData.groups.find(
                (group) => group.id == props.data.groupIds[0]
              ).name ? (
                <Chip
                  sx={chipStyles}
                  label={
                    props.teamData.groups.find(
                      (group) => group.id == props.data.groupIds[0]
                    ).name
                  }
                />
            ) : (
                <Typography sx={{ 
                    fontSize: '0.875rem', 
                    fontWeight: 500, 
                    color: '#9ca3af'
                }}>
                  No Campaigns
                </Typography>

            )}
          
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: '100%'
        }}>
            {selectedGroups.length > 1 && (
                <Typography sx={{ 
                    fontSize: '0.875rem', 
                    fontWeight: 500,
                    color: '#6b7280'
                }}>
                    {`+ ${selectedGroups.length - 1}`}
                </Typography>
            )}
        </Box>
      </>
    </ButtonBase>
  ) : (
    <Box sx={{ display: "flex", flexDirection:"row", gap: 1, width:"100%" }}>
        {cellView ? (
            !disablePopper && <CustomPopper  
                cellRef={cellRef} 
                setCellViewOpen={setCellViewOpen} 
                cellViewOpen={cellViewOpen}
            >
                {autocompleteComponent}
            </CustomPopper>
        ) : autocompleteComponent}
    </Box>
    )}
  </Box>

  );
};

export default GroupPicker;

const CustomPopper = (props) => {
    return (
        <ClickAwayListener onClickAway={() => props.setCellViewOpen(false)}>
            <Popper
                {...props}
                open={props.cellViewOpen}
                anchorEl={props.cellRef?.current || document.body}
                placement="bottom"
                style={{
                    zIndex: 1000,
                    minWidth: 450
                }}
            >
                <Box sx={{ 
                    p: 1.5,
                    background: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    maxWidth: 450
                }}>
                    {props.children}
                </Box>
            </Popper>
        </ClickAwayListener>
    );
  };
